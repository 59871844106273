import { call, put } from 'redux-saga/effects';

import { Creators as PacotesActions } from '../ducks/pacotes';

import API from '../../services/api';

export function* listarPacotes() {
  try {
    const { data } = yield call(API.get, 'financeiro/listar-pacote');
    if (!data.records.length) {
      yield put(
        PacotesActions.listPacotesFailure(
          'Nenhum pacote cadastrado até o momento!'
        )
      );
    } else {
      const { records } = data;
      yield put(PacotesActions.listPacotesSuccess(records));
    }
  } catch (err) {
    yield put(
      PacotesActions.listPacotesFailure(
        'Erro interno no módulo de listagem de pacotes!'
      )
    );
  }
}
