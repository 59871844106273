/**
 * Types
 */
export const Types = {
  LIST_REQUEST: '@pacotes/LIST_REQUEST',
  LIST_SUCCESS: '@pacotes/LIST_SUCCESS',
  LIST_FAILURE: '@pacotes/LIST_FAILURE',
};

/**
 * Reducer
 */
const INITIAL_STATE = {
  error: null,
  data: [],
};

export default function pacotes(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LIST_REQUEST:
      return { ...state };

    case Types.LIST_SUCCESS:
      return {
        ...state,
        error: null,
        data: [...state.data, ...action.payload.data],
      };

    case Types.LIST_FAILURE:
      return { ...state, error: action.payload.error };

    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  listPacotesRequest: () => ({
    type: Types.LIST_REQUEST,
  }),

  listPacotesSuccess: data => ({
    type: Types.LIST_SUCCESS,
    payload: { data },
  }),

  listPacotesFailure: error => ({
    type: Types.LIST_FAILURE,
    payload: { error },
  }),
};
