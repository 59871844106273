import { call, put } from 'redux-saga/effects';

import { Creators as RegisterActions } from '../ducks/register';

import API from '../../services/api';

export function* registrarNovoCliente(action) {
  try {
    const { payload } = action;
    const { data } = yield call(
      API.post,
      '/psico-auth/registrar',
      payload.object
    );

    const { records } = data;
    if (records.error) {
      yield put(RegisterActions.doRegisterFailure(records.msg));
    } else {
      yield put(RegisterActions.doRegisterSuccess(records.msg));
    }
  } catch (err) {
    yield put(
      RegisterActions.doRegisterFailure(
        'Erro interno no módulo para registrar um novo cliente!'
      )
    );
  }
}
