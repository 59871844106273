const Config = {
  URL_IMAGE_BIG:
    'https://app.psicomanager.com/gc/index/get-foto-paciente/?file=g-',
  URL_IMAGE_SMALL:
    'https://app.psicomanager.com/gc/index/get-foto-paciente/?file=p-',
  URL_API_PRODUCTION: 'https://apip.psicomanager.com/v1/1005/psico',
  URL_API_DEVELOPMENT: 'https://apip.psicomanager.com/v1/1005/psico',
};

/** http://localhost:8081/apip/v1/1005/psico */

export default Config;
