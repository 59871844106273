import { call, put } from 'redux-saga/effects';

import { Creators as GrupoActions } from '../ducks/grupos';

import API from '../../services/api';

export function* listarGrupos() {
  try {
    const { data } = yield call(API.get, '/paciente/listar-grupo/');
    if (!data.records.length) {
      yield put(
        GrupoActions.listGruposFailure('Nenhum grupo cadastrado até o momento!')
      );
    } else {
      const { records } = data;
      yield put(GrupoActions.listGruposSuccess(records));
    }
  } catch (err) {
    yield put(
      GrupoActions.listGruposFailure(
        'Erro interno no módulo de listagem de grupos!'
      )
    );
  }
}
