import { call, put } from 'redux-saga/effects';

import { Creators as SalaActions } from '../ducks/salas';

import API from '../../services/api';

export function* listarSalas() {
  try {
    const { data } = yield call(API.get, '/sessoes/sala');
    if (!data.records.length) {
      yield put(
        SalaActions.listSalasFailure('Nenhuma sala cadastrada até o momento!')
      );
    } else {
      const { records } = data;
      yield put(SalaActions.listSalasSuccess(records));
    }
  } catch (err) {
    yield put(
      SalaActions.listSalasFailure(
        'Erro interno no módulo de listagem de salas!'
      )
    );
  }
}
