/**
 * Types
 */
export const Types = {
  LIST_SCHEDULE_REQUEST: '@agendas/LIST_SCHEDULE_REQUEST',
  LIST_SCHEDULE_SUCCESS: '@agendas/LIST_SCHEDULE_SUCCESS',
  LIST_SCHEDULE_FAILURE: '@agendas/LIST_SCHEDULE_FAILURE',
  ATTACH_SCHEDULE_REQUEST: '@agendas/ATTACH_SCHEDULE_REQUEST',
  ATTACH_SCHEDULE_SUCCESS: '@agendas/ATTACH_SCHEDULE_SUCCESS',
  ATTACH_SCHEDULE_FAILURE: '@agendas/ATTACH_SCHEDULE_FAILURE',
  RESET_EVENT_SCHEDULE_REQUEST: '@agendas/RESET_EVENT_SCHEDULE_REQUEST',
  SAVE_EVENTS_SCHEDULE_REQUEST: '@agendas/SAVE_EVENTS_SCHEDULE_REQUEST',
  SAVE_EVENTS_SCHEDULE_SUCCESS: '@agendas/SAVE_EVENTS_SCHEDULE_SUCCESS',
  SAVE_EVENTS_SCHEDULE_FAILURE: '@agendas/SAVE_EVENTS_SCHEDULE_FAILURE',
  REMOVE_EVENT_SCHEDULE_REQUEST: '@agendas/REMOVE_EVENT_SCHEDULE_REQUEST',
  REMOVE_EVENT_SCHEDULE_SUCCESS: '@agendas/REMOVE_EVENT_SCHEDULE_SUCCESS',
  REMOVE_EVENT_SCHEDULE_FAILURE: '@agendas/REMOVE_EVENT_SCHEDULE_FAILURE',
  STACK_EVENT_IN_SCHEDULE_REQUEST: '@agendas/STACK_EVENT_IN_SCHEDULE_REQUEST',
  DETACH_EVENT_IN_SCHEDULE_REQUEST: '@agendas/DETACH_EVENT_IN_SCHEDULE_REQUEST',
  LIST_SCHEDULE_AND_ROOMS_REQUEST: '@agendas/LIST_SCHEDULE_AND_ROOMS_REQUEST',
  LIST_SCHEDULE_AND_ROOMS_SUCCESS: '@agendas/LIST_SCHEDULE_AND_ROOMS_SUCCESS',
  LIST_SCHEDULE_AND_ROOMS_FAILURE: '@agendas/LIST_SCHEDULE_AND_ROOMS_FAILURE',
  CLEAR_SCHEDULE_AND_ROOMS_SUCCESS: '@agendas/CLEAR_SCHEDULE_AND_ROOMS_SUCCESS',
};

/**
 * Reducer
 */
const INITIAL_STATE = {
  id: null,
  data: [],
  dataSalas: null,
  query: '',
  error: null,
  timeunix: null,
  event: {},
  object: {},
};

export default function agendas(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LIST_SCHEDULE_REQUEST:
    case Types.ATTACH_SCHEDULE_REQUEST:
    case Types.LIST_SCHEDULE_AND_ROOMS_REQUEST:
      return { ...state, query: action.payload.query };

    case Types.LIST_SCHEDULE_SUCCESS:
      return {
        ...state,
        error: null,
        timeunix: null,
        data: [...action.payload.data],
      };

    case Types.ATTACH_SCHEDULE_SUCCESS:
      return {
        ...state,
        error: null,
        timeunix: null,
        data: action.payload.data,
        // data: [...state.data, ...action.payload.data],
      };

    case Types.LIST_SCHEDULE_AND_ROOMS_SUCCESS:
      return {
        ...state,
        error: null,
        dataSalas: action.payload.data,
      };

    case Types.SAVE_EVENTS_SCHEDULE_REQUEST:
      return {
        ...state,
        error: null,
        event: {},
        object: action.payload.object,
      };

    case Types.SAVE_EVENTS_SCHEDULE_SUCCESS:
    case Types.REMOVE_EVENT_SCHEDULE_SUCCESS:
      return { ...state, error: null, timeunix: action.payload.timeunix };

    case Types.LIST_SCHEDULE_FAILURE:
    case Types.ATTACH_SCHEDULE_FAILURE:
    case Types.SAVE_EVENTS_SCHEDULE_FAILURE:
    case Types.REMOVE_EVENT_SCHEDULE_FAILURE:
    case Types.LIST_SCHEDULE_AND_ROOMS_FAILURE:
      return { ...state, timeunix: null, error: action.payload.error };

    case Types.STACK_EVENT_IN_SCHEDULE_REQUEST:
      return {
        ...state,
        error: null,
        event: action.payload.event,
        data: [...state.data, action.payload.event],
      };

    case Types.REMOVE_EVENT_SCHEDULE_REQUEST:
      return { ...state, error: null, id: action.payload.id };

    case Types.DETACH_EVENT_IN_SCHEDULE_REQUEST:
      return { ...state, error: null, data: action.payload.eventos };

    case Types.RESET_EVENT_SCHEDULE_REQUEST:
      return {
        ...state,
        id: null,
        error: null,
        timeunix: null,
      };

    case Types.CLEAR_SCHEDULE_AND_ROOMS_SUCCESS:
      return {
        ...state,
        error: null,
        dataSalas: null,
      };

    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  listAgendasRequest: query => ({
    type: Types.LIST_SCHEDULE_REQUEST,
    payload: { query },
  }),

  listAgendasSuccess: data => ({
    type: Types.LIST_SCHEDULE_SUCCESS,
    payload: { data },
  }),

  listAgendasFailure: error => ({
    type: Types.LIST_SCHEDULE_FAILURE,
    payload: { error },
  }),

  attachAgendasRequest: query => ({
    type: Types.ATTACH_SCHEDULE_REQUEST,
    payload: { query },
  }),

  attachAgendasSuccess: data => ({
    type: Types.ATTACH_SCHEDULE_SUCCESS,
    payload: { data },
  }),

  attachAgendasFailure: error => ({
    type: Types.ATTACH_SCHEDULE_FAILURE,
    payload: { error },
  }),

  saveEventoAgendaRequest: object => ({
    type: Types.SAVE_EVENTS_SCHEDULE_REQUEST,
    payload: { object },
  }),

  saveEventoAgendaSuccess: timeunix => ({
    type: Types.SAVE_EVENTS_SCHEDULE_SUCCESS,
    payload: { timeunix },
  }),

  saveEventoAgendaFailure: error => ({
    type: Types.SAVE_EVENTS_SCHEDULE_FAILURE,
    payload: { error },
  }),

  stackEventoInAgendaRequest: event => ({
    type: Types.STACK_EVENT_IN_SCHEDULE_REQUEST,
    payload: { event },
  }),

  detachEventoInAgendaRequest: eventos => ({
    type: Types.DETACH_EVENT_IN_SCHEDULE_REQUEST,
    payload: { eventos },
  }),

  removeEventoAgendaRequest: id => ({
    type: Types.REMOVE_EVENT_SCHEDULE_REQUEST,
    payload: { id },
  }),

  removeEventoAgendaSuccess: timeunix => ({
    type: Types.REMOVE_EVENT_SCHEDULE_SUCCESS,
    payload: { timeunix },
  }),

  removeEventoAgendaFailure: error => ({
    type: Types.REMOVE_EVENT_SCHEDULE_FAILURE,
    payload: { error },
  }),

  resetEventoAgendaRequest: () => ({
    type: Types.RESET_EVENT_SCHEDULE_REQUEST,
  }),

  listAgendaSalasRequest: query => ({
    type: Types.LIST_SCHEDULE_AND_ROOMS_REQUEST,
    payload: { query },
  }),

  listAgendaSalasSuccess: data => ({
    type: Types.LIST_SCHEDULE_AND_ROOMS_SUCCESS,
    payload: { data },
  }),

  listAgendaSalasFailure: error => ({
    type: Types.LIST_SCHEDULE_AND_ROOMS_FAILURE,
    payload: { error },
  }),

  clearAgendaSalasRequest: () => ({
    type: Types.CLEAR_SCHEDULE_AND_ROOMS_SUCCESS,
  }),
};
