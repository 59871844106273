/* eslint-disable no-shadow */

/**
 * Types
 */
export const Types = {
  LIST_REQUEST: '@anamneses/LIST_REQUEST',
  LIST_SUCCESS: '@anamneses/LIST_SUCCESS',
  LIST_FAILURE: '@anamneses/LIST_FAILURE',
  CLEAR_ANAMNESIS_GROUP: '@anamneses/CLEAR_ANAMNESIS_GROUP',
  SAVE_ANAMNESIS_REQUEST: '@anamneses/SAVE_ANAMNESIS_REQUEST',
  SAVE_ANAMNESIS_SUCCESS: '@anamneses/SAVE_ANAMNESIS_SUCCESS',
  SAVE_ANAMNESIS_FAILURE: '@anamneses/SAVE_ANAMNESIS_FAILURE',
  GET_ANAMNESIS_GROUP_REQUEST: '@anamneses/GET_ANAMNESIS_GROUP_REQUEST',
  GET_ANAMNESIS_GROUP_SUCCESS: '@anamneses/GET_ANAMNESIS_GROUP_SUCCESS',
  GET_ANAMNESIS_GROUP_FAILURE: '@anamneses/GET_ANAMNESIS_GROUP_FAILURE',
};

/**
 * Reducer
 */
const INITIAL_STATE = {
  data: [],
  error: null,
  success: null,
  rowbody: {},
  idPaciente: 0,
  anamneses: null,
};

export default function anamneses(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LIST_REQUEST:
      return { ...state };

    case Types.LIST_SUCCESS:
      return {
        ...state,
        error: null,
        data: [...state.data, ...action.payload.data],
      };

    case Types.LIST_FAILURE:
    case Types.SAVE_ANAMNESIS_FAILURE:
    case Types.GET_ANAMNESIS_GROUP_FAILURE:
      return { ...state, success: null, error: action.payload.error };

    case Types.GET_ANAMNESIS_GROUP_REQUEST:
      return { ...state, error: null, idPaciente: action.payload.idPaciente };

    case Types.GET_ANAMNESIS_GROUP_SUCCESS:
      return { ...state, error: null, anamneses: action.payload.anamneses };

    case Types.SAVE_ANAMNESIS_REQUEST:
      return {
        ...state,
        rowbody: action.payload.rowbody,
      };

    case Types.SAVE_ANAMNESIS_SUCCESS:
      return { ...state, error: null, success: action.payload.success };

    case Types.CLEAR_ANAMNESIS_GROUP:
      return {
        ...state,
        error: null,
        rowbody: {},
        idPaciente: 0,
        success: null,
        anamneses: null,
      };

    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  listAnamnesesRequest: () => ({
    type: Types.LIST_REQUEST,
  }),

  listAnamnesesSuccess: data => ({
    type: Types.LIST_SUCCESS,
    payload: { data },
  }),

  listAnamnesesFailure: error => ({
    type: Types.LIST_FAILURE,
    payload: { error },
  }),

  getGrupoAnamneseRequest: idPaciente => ({
    type: Types.GET_ANAMNESIS_GROUP_REQUEST,
    payload: { idPaciente },
  }),

  getGrupoAnamneseSuccess: anamneses => ({
    type: Types.GET_ANAMNESIS_GROUP_SUCCESS,
    payload: { anamneses },
  }),

  getGrupoAnamneseFailure: error => ({
    type: Types.GET_ANAMNESIS_GROUP_FAILURE,
    payload: { error },
  }),

  saveGrupoAnamneseRequest: rowbody => ({
    type: Types.SAVE_ANAMNESIS_REQUEST,
    payload: { rowbody },
  }),

  saveGrupoAnamneseSuccess: success => ({
    type: Types.SAVE_ANAMNESIS_SUCCESS,
    payload: { success },
  }),

  saveGrupoAnamneseFailure: error => ({
    type: Types.SAVE_ANAMNESIS_FAILURE,
    payload: { error },
  }),

  resetGrupoAnamneseRequest: () => ({
    type: Types.CLEAR_ANAMNESIS_GROUP,
  }),
};
