import { call, put } from 'redux-saga/effects';

import { Creators as EmpresaActions } from '../ducks/empresa';

import { TOKEN_KEY_PIN } from '../../services/storage';

import API from '../../services/api';

export function* verificarNumeroPin() {
  try {
    const { data } = yield call(API.get, '/empresa/verificar-numero-pin');
    if (data.records) {
      yield put(EmpresaActions.checkNumeroPinEmpresaSuccess(true));
    } else {
      yield put(EmpresaActions.checkNumeroPinEmpresaFailure(false));
    }
  } catch (err) {
    yield put(EmpresaActions.checkNumeroPinEmpresaFailure(false));
  }
}

export function* liberarNumeroPin(action) {
  try {
    const { payload } = action;
    const { data } = yield call(
      API.post,
      '/empresa/liberar-numero-pin',
      payload.rowbody
    );
    if (data.records.error) {
      yield put(EmpresaActions.releaseNumeroPinFailure(false));
    } else {
      localStorage.setItem(TOKEN_KEY_PIN, data.records.expires);
      yield put(EmpresaActions.releaseNumeroPinSuccess(true));
    }
  } catch (err) {
    yield put(EmpresaActions.releaseNumeroPinFailure(false));
  }
}

export function* getEmpresaConfig() {
  try {
    const { data } = yield call(API.get, '/empresa/get-empresa-profissional');
    if (data.records) {
      yield put(EmpresaActions.getConfigEmpresaSuccess(data.records));
    } else {
      yield put(EmpresaActions.getConfigEmpresaFailure(true));
    }
  } catch (err) {
    yield put(EmpresaActions.getConfigEmpresaFailure(true));
  }
}
