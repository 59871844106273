import { call, put } from 'redux-saga/effects';

import { Creators as ClienteActions } from '../ducks/clientes';

import API from '../../services/api';

export function* listarClientes(action) {
  try {
    const { payload } = action;
    const criteria = payload.search.criteria
      ? `,criteria:${payload.search.criteria}`
      : '';

    const { data } = yield call(
      API.get,
      `/paciente?q=(offset:${payload.search.offset}${criteria})`
    );
    if (!data.records.length) {
      yield put(
        ClienteActions.listClientesFailure(
          'Nenhum cliente cadastrado até o momento!'
        )
      );
    } else {
      const { records } = data;
      yield put(ClienteActions.listClientesSuccess(records));
    }
  } catch (err) {
    yield put(
      ClienteActions.listClientesFailure(
        'Erro interno no módulo de listagem de clientes!'
      )
    );
  }
}

export function* paginarClientes(action) {
  try {
    const { payload } = action;
    const criteria = payload.search.criteria
      ? `,criteria:${payload.search.criteria}`
      : '';

    const { data } = yield call(
      API.get,
      `/paciente?q=(offset:${payload.search.offset}${criteria})`
    );
    if (!data.records.length) {
      yield put(
        ClienteActions.pageClientesFailure(
          'Não existem mais registros de clientes!'
        )
      );
    } else {
      const { records } = data;
      yield put(ClienteActions.pageClientesSuccess(records));
    }
  } catch (err) {
    yield put(
      ClienteActions.pageClientesFailure(
        'Erro interno no módulo de paginar de clientes!'
      )
    );
  }
}

export function* getCliente(action) {
  try {
    const { payload } = action;
    const { data } = yield call(API.get, `/paciente/${payload.id}`);
    if (data.records) {
      const { records } = data;
      yield put(ClienteActions.getClienteSuccess(records));
    } else {
      yield put(ClienteActions.getClienteFailure('Cliente inexistente!'));
    }
  } catch (err) {
    yield put(
      ClienteActions.getClienteFailure(
        'Erro interno no módulo de buscar os dados de um determinado cliente!'
      )
    );
  }
}

export function* saveCliente(action) {
  try {
    const { payload } = action;

    let response = null;
    if (payload.object.cod_paciente) {
      response = yield call(
        API.put,
        `/paciente/${payload.object.cod_paciente}`,
        payload.object
      );
    } else {
      response = yield call(API.post, '/paciente/', payload.object);
    }

    const { data } = response;
    if (data.records.error) {
      yield put(
        ClienteActions.saveClienteFailure(
          'Ocorreu um erro ao tentar salvar os dados do cliente!'
        )
      );
    } else {
      yield put(
        ClienteActions.saveClienteSuccess('Cliente salvo com sucesso! =)')
      );
    }
  } catch (err) {
    yield put(
      ClienteActions.saveClienteFailure(
        'Erro interno no módulo de salvar os dados do cliente!'
      )
    );
  }
}
