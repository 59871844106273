/**
 * Types
 */
export const Types = {
  RESET_REQUEST: '@campos/RESET_REQUEST',
  GET_REQUEST: '@campos/GET_REQUEST',
  GET_SUCCESS: '@campos/GET_SUCCESS',
  GET_FAILURE: '@campos/GET_FAILURE',
};

/**
 * Reducer
 */
const INITIAL_STATE = {
  data: [],
  error: null,
  idServico: 0,
};

export default function campos(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_REQUEST:
      return { ...state, data: [], error: null };

    case Types.GET_REQUEST:
      return { ...state, idServico: action.payload.idServico };

    case Types.GET_SUCCESS:
      return {
        ...state,
        ok: null,
        error: null,
        data: [...action.payload.data],
      };

    case Types.GET_FAILURE:
      return { ...state, error: action.payload.error };

    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  resetCamposFormularioRequest: () => ({
    type: Types.RESET_REQUEST,
  }),

  getCamposFormularioRequest: idServico => ({
    type: Types.GET_REQUEST,
    payload: { idServico },
  }),

  getCamposFormularioSuccess: data => ({
    type: Types.GET_SUCCESS,
    payload: { data },
  }),

  getCamposFormularioFailure: error => ({
    type: Types.GET_FAILURE,
    payload: { error },
  }),
};
