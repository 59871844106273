import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Snackbar } from '@material-ui/core';
import MySnackbarContentWrapper from '../Snackbar';

class Offline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      online: navigator.onLine,
    };
  }

  componentDidMount() {
    window.addEventListener('online', this.handleNetworkChnage);
    window.addEventListener('offline', this.handleNetworkChnage);
  }

  componentWillUnmount() {
    window.addEventListener('online', this.handleNetworkChnage);
    window.addEventListener('offline', this.handleNetworkChnage);
  }

  handleNetworkChnage = () => {
    this.setState({ online: navigator.onLine });
  };

  render() {
    const { text } = this.props;
    const { online } = this.state;
    return (
      <>
        {!online && (
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open
            autoHideDuration={10000}
            onClose={() => {}}
          >
            <MySnackbarContentWrapper
              onClose={() => {}}
              variant="error"
              message={text}
              actionClose={false}
            />
          </Snackbar>
        )}
      </>
    );
  }
}

Offline.defaultProps = {
  text: 'Você está offline! :(',
};

Offline.propTypes = {
  text: PropTypes.string,
};

export default Offline;
