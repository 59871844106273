/**
 * Types
 */
export const Types = {
  DO_REGISTER_REQUEST: '@register/DO_REGISTER_REQUEST',
  DO_REGISTER_SUCCESS: '@register/DO_REGISTER_SUCCESS',
  DO_REGISTER_FAILURE: '@register/DO_REGISTER_FAILURE',
};

/**
 * Reducer
 */
const INITIAL_STATE = {
  ok: null,
  error: null,
  object: {},
};

export default function register(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.DO_REGISTER_REQUEST:
      return { ...state, error: null, object: action.payload.object };

    case Types.DO_REGISTER_SUCCESS:
      return {
        ...state,
        error: null,
        ok: action.payload.ok,
      };

    case Types.DO_REGISTER_FAILURE:
      return {
        ...state,
        ok: null,
        error: action.payload.error,
      };

    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  doRegisterRequest: object => ({
    type: Types.DO_REGISTER_REQUEST,
    payload: { object },
  }),

  doRegisterSuccess: ok => ({
    type: Types.DO_REGISTER_SUCCESS,
    payload: { ok },
  }),

  doRegisterFailure: error => ({
    type: Types.DO_REGISTER_FAILURE,
    payload: { error },
  }),
};
