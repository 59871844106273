export const TOKEN_KEY = '@psico';
export const TOKEN_KEY_PIN = '@psico:pin';
export const getStorage = () => JSON.parse(localStorage.getItem(TOKEN_KEY));
export const getToken = () => JSON.parse(localStorage.getItem(TOKEN_KEY)).token;
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(TOKEN_KEY_PIN);
  window.location.href = '/';
};
