import { call, put } from 'redux-saga/effects';

import { Creators as LoginActions } from '../ducks/login';

import { TOKEN_KEY } from '../../services/storage';

import API from '../../services/api';

export function* efetuarLogin(action) {
  try {
    const { payload } = action;
    const { data } = yield call(API.post, '/psico-auth', payload.object);

    if (data.records.error) {
      const { records } = data;
      yield put(LoginActions.doLoginFailure(records.msg));
    } else {
      localStorage.setItem(TOKEN_KEY, JSON.stringify(data.records));
      yield put(LoginActions.doLoginSuccess('Login realizado com sucesso! =)'));
    }
  } catch (err) {
    yield put(LoginActions.doLoginFailure('Erro interno no módulo de login!'));
  }
}
