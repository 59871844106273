import { call, put } from 'redux-saga/effects';

import { Creators as FinancaActions } from '../ducks/financas';

import API from '../../services/api';

export function* listarReceitasFinanceiras(action) {
  try {
    const { payload } = action;
    const { data } = yield call(
      API.get,
      `/financeiro/listar-receita/${payload.query}`
    );

    const permissao = !(
      Object.prototype.hasOwnProperty.call(data.records, 'perm') &&
      data.records.perm === false
    );
    yield put(FinancaActions.setPermissaoFinancasNormal(permissao));

    if (!data.records.length) {
      yield put(
        FinancaActions.listReceitasFinanceirasFailure(
          'Nenhuma receita financeira encontrada!'
        )
      );
    } else {
      const { records } = data;
      yield put(FinancaActions.listReceitasFinanceirasSuccess(records));
    }
  } catch (err) {
    yield put(
      FinancaActions.listReceitasFinanceirasFailure(
        'Erro interno no módulo de listagem de receitas financeiras!'
      )
    );
  }
}

export function* listarContasReceberPorSessao(action) {
  try {
    const { payload } = action;
    const { data } = yield call(
      API.get,
      `/sessao/detalhe-contas-receber-sessao/${payload.idReceita}`
    );
    if (data.records) {
      yield put(FinancaActions.listContasReceberSessaoSuccess(data.records));
    } else {
      yield put(
        FinancaActions.listContasReceberSessaoFailure(
          'Nenhuma conta a receber cadastrada até o momento!'
        )
      );
    }
  } catch (err) {
    yield put(
      FinancaActions.listContasReceberSessaoFailure(
        'Erro interno no módulo de listagem das contas a receber por sessão!'
      )
    );
  }
}

export function* saveReceitaDespesa(action) {
  try {
    const { payload } = action;
    const { data } = yield call(
      API.post,
      '/financeiro/atualizar-pagar-receber',
      payload.object
    );
    if (data.records.error) {
      const { records } = data;
      yield put(FinancaActions.saveReceitaDespesaFailure(records.msg));
    } else {
      yield put(
        FinancaActions.saveReceitaDespesaSuccess('Dados salvos com sucesso! =)')
      );
    }
  } catch (err) {
    yield put(
      FinancaActions.saveReceitaDespesaFailure(
        'Erro interno no módulo de salvar um receita e despesa!'
      )
    );
  }
}

export function* saveReceitaDespesaMensal(action) {
  try {
    const { payload } = action;
    const { data } = yield call(
      API.post,
      '/financeiro/atualizar-pagar-receber-mensal',
      payload.object
    );
    if (data.records.error) {
      const { records } = data;
      yield put(FinancaActions.saveReceitaDespesaMensalFailure(records.msg));
    } else {
      yield put(
        FinancaActions.saveReceitaDespesaMensalSuccess(
          'Dados salvos com sucesso! =)'
        )
      );
    }
  } catch (err) {
    yield put(
      FinancaActions.saveReceitaDespesaMensalFailure(
        'Erro interno no módulo de salvar um receita e despesa!'
      )
    );
  }
}

export function* getReceitaDespesa(action) {
  try {
    const { payload } = action;
    const service =
      payload.objFiltroReceitaDespesa.type === 'receita'
        ? 'get-contas-receber'
        : 'get-contas-pagar';

    const { data } = yield call(
      API.get,
      `/financeiro/${service}/${payload.objFiltroReceitaDespesa.id}`
    );

    if (data.records) {
      const { records } = data;
      yield put(FinancaActions.getReceitaOuDespesaSuccess(records));
    } else {
      yield put(
        FinancaActions.getReceitaOuDespesaFailure('Finança inexistente!')
      );
    }
  } catch (err) {
    yield put(
      FinancaActions.getReceitaOuDespesaFailure(
        'Erro interno no módulo de recolher os dados de uma despesa ou receita!'
      )
    );
  }
}

export function* saveFinanceiro(action) {
  try {
    const { payload } = action;

    let response = null;
    if (payload.objectFinancial.cod_receita) {
      response = yield call(
        API.post,
        '/financeiro/atualizar-receita',
        payload.objectFinancial
      );
    } else {
      response = yield call(
        API.post,
        '/financeiro/inserir-receita',
        payload.objectFinancial
      );
    }

    const { data } = response;
    if (data.records.error) {
      const { records } = data;
      yield put(FinancaActions.saveFinanceiroFailure(records.msg));
    } else {
      yield put(
        FinancaActions.saveFinanceiroSuccess('Dados salvos com sucesso! =)')
      );
    }
  } catch (err) {
    yield put(
      FinancaActions.saveFinanceiroFailure(
        'Erro interno no módulo de salvar os dados do financeiro!'
      )
    );
  }
}

export function* totalizarReceitas(action) {
  try {
    const { payload } = action;
    const { data } = yield call(
      API.get,
      `/financeiro/totalizar-financas/${payload.query}`
    );
    const { records } = data;
    yield put(FinancaActions.totalReceitasFinanceirasSuccess(records));
  } catch (err) {
    yield put(
      FinancaActions.totalReceitasFinanceirasFailure(
        'Erro interno no módulo de totalizar as receitas financeiras!'
      )
    );
  }
}
