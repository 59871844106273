/**
 * Types
 */
export const Types = {
  GET_REQUEST: '@clientes/GET_REQUEST',
  GET_SUCCESS: '@clientes/GET_SUCCESS',
  GET_FAILURE: '@clientes/GET_FAILURE',
  PAGE_REQUEST: '@clientes/PAGE_REQUEST',
  PAGE_SUCCESS: '@clientes/PAGE_SUCCESS',
  PAGE_FAILURE: '@clientes/PAGE_FAILURE',
  LIST_REQUEST: '@clientes/LIST_REQUEST',
  LIST_SUCCESS: '@clientes/LIST_SUCCESS',
  LIST_FAILURE: '@clientes/LIST_FAILURE',
  SAVE_REQUEST: '@clientes/SAVE_REQUEST',
  SAVE_SUCCESS: '@clientes/SAVE_SUCCESS',
  SAVE_FAILURE: '@clientes/SAVE_FAILURE',
  RESET_OBJECT: '@clientes/RESET_OBJECT',
  RESET_CLIENTS: '@clientes/RESET_CLIENTS',
};

/**
 * Reducer
 */
const INITIAL_STATE = {
  id: 0,
  ok: null,
  data: [] /* Retorno para a listagem geral dos clientes. */,
  error: null,
  object: {} /* Objeto enviado para salvar os dados do cliente. */,
  search: {
    offset: 0,
    criteria: '',
  },
};

export default function clientes(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LIST_REQUEST:
      return {
        ...state,
        search: action.payload.search,
      };

    case Types.LIST_SUCCESS:
      return {
        ...state,
        ok: null,
        error: null,
        data: [...action.payload.data],
      };

    case Types.LIST_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        data: action.payload.data,
      };

    case Types.PAGE_REQUEST:
      return {
        ...state,
        search: action.payload.search,
      };

    case Types.PAGE_SUCCESS:
      return {
        ...state,
        ok: null,
        error: null,
        data: [...state.data, ...action.payload.data],
      };

    case Types.PAGE_FAILURE:
      return { ...state, error: action.payload.error };

    case Types.GET_REQUEST:
      return { ...state, id: action.payload.id };

    case Types.GET_SUCCESS:
      return { ...state, error: null, object: action.payload.object };

    case Types.GET_FAILURE:
      return { ...state, error: action.payload.error };

    case Types.SAVE_REQUEST:
      return { ...state, object: action.payload.object };

    case Types.SAVE_SUCCESS:
      return { ...state, error: null, ok: action.payload.ok };

    case Types.SAVE_FAILURE:
      return { ...state, ok: null, error: action.payload.error };

    case Types.RESET_OBJECT:
      return {
        ...state,
        id: 0,
        ok: null,
        error: null,
        object: {},
      };

    case Types.RESET_CLIENTS:
      return {
        ...state,
        ok: null,
        error: null,
        data: [],
        search: {
          offset: 0,
          criteria: '',
        },
      };

    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  resetClienteRequest: () => ({
    type: Types.RESET_OBJECT,
  }),

  resetListagemClientesRequest: () => ({
    type: Types.RESET_CLIENTS,
  }),

  listClientesRequest: search => ({
    type: Types.LIST_REQUEST,
    payload: { search },
  }),

  listClientesSuccess: data => ({
    type: Types.LIST_SUCCESS,
    payload: { data },
  }),

  listClientesFailure: error => ({
    type: Types.LIST_FAILURE,
    payload: { error, data: [] },
  }),

  pageClientesRequest: search => ({
    type: Types.PAGE_REQUEST,
    payload: { search },
  }),

  pageClientesSuccess: data => ({
    type: Types.PAGE_SUCCESS,
    payload: { data },
  }),

  pageClientesFailure: error => ({
    type: Types.PAGE_FAILURE,
    payload: { error },
  }),

  getClienteRequest: id => ({
    type: Types.GET_REQUEST,
    payload: { id },
  }),

  getClienteSuccess: object => ({
    type: Types.GET_SUCCESS,
    payload: { object },
  }),

  getClienteFailure: error => ({
    type: Types.GET_FAILURE,
    payload: { error },
  }),

  saveClienteRequest: object => ({
    type: Types.SAVE_REQUEST,
    payload: { object },
  }),

  saveClienteSuccess: ok => ({
    type: Types.SAVE_SUCCESS,
    payload: { ok },
  }),

  saveClienteFailure: error => ({
    type: Types.SAVE_FAILURE,
    payload: { error },
  }),
};
