/**
 * Types
 */
export const Types = {
  DO_LOGIN_REQUEST: '@login/DO_LOGIN_REQUEST',
  DO_LOGIN_SUCCESS: '@login/DO_LOGIN_SUCCESS',
  DO_LOGIN_FAILURE: '@login/DO_LOGIN_FAILURE',
};

/**
 * Reducer
 */
const INITIAL_STATE = {
  ok: null,
  error: null,
  object: {},
};

export default function login(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.DO_LOGIN_REQUEST:
      return { ...state, object: action.payload.object };

    case Types.DO_LOGIN_SUCCESS:
      return {
        ...state,
        error: null,
        ok: action.payload.ok,
      };

    case Types.DO_LOGIN_FAILURE:
      return {
        ...state,
        ok: null,
        error: action.payload.error,
      };

    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  doLoginRequest: object => ({
    type: Types.DO_LOGIN_REQUEST,
    payload: { object },
  }),

  doLoginSuccess: ok => ({
    type: Types.DO_LOGIN_SUCCESS,
    payload: { ok },
  }),

  doLoginFailure: error => ({
    type: Types.DO_LOGIN_FAILURE,
    payload: { error },
  }),
};
