import { call, put } from 'redux-saga/effects';

import { Creators as ConvenioActions } from '../ducks/convenios';

import API from '../../services/api';

export function* listarConvenios() {
  try {
    const { data } = yield call(API.get, '/empresa/convenio');
    if (!data.records.length) {
      yield put(
        ConvenioActions.listConveniosFailure(
          'Nenhum convênio cadastrado até o momento!'
        )
      );
    } else {
      const { records } = data;
      yield put(ConvenioActions.listConveniosSuccess(records));
    }
  } catch (err) {
    yield put(
      ConvenioActions.listConveniosFailure(
        'Erro interno no módulo de listagem de convênios!'
      )
    );
  }
}
