/**
 * Types
 */
export const Types = {
  GET_VIEW_NAME: '@componentes/GET_VIEW_NAME',
  CHANGE_VIEW_NAME: '@componentes/CHANGE_VIEW_NAME',
};

/**
 * Reducer
 */
const INITIAL_STATE = {
  info: {},
};

export default function componentes(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_VIEW_NAME:
      return { ...state };

    case Types.CHANGE_VIEW_NAME:
      return { ...state, info: action.payload.info };

    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  getViewNameRequest: () => ({
    type: Types.GET_VIEW_NAME,
  }),

  changeViewNameRequest: info => ({
    type: Types.CHANGE_VIEW_NAME,
    payload: { info },
  }),
};
