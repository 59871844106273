import { combineReducers } from 'redux';

import salas from './salas';
import login from './login';
import campos from './campos';
import grupos from './grupos';
import empresa from './empresa';
import sessoes from './sessoes';
import agendas from './agendas';
import pacotes from './pacotes';
import financas from './financas';
import horarios from './horarios';
import servicos from './servicos';
import clientes from './clientes';
import register from './register';
import convenios from './convenios';
import anamneses from './anamneses';
import anotacoes from './anotacoes';
import componentes from './componentes';

export default combineReducers({
  salas,
  login,
  campos,
  grupos,
  empresa,
  sessoes,
  agendas,
  pacotes,
  financas,
  horarios,
  servicos,
  clientes,
  register,
  convenios,
  anamneses,
  anotacoes,
  componentes,
});
