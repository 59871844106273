/**
 * Types
 */
export const Types = {
  GET_REQUEST: '@sessoes/GET_REQUEST',
  GET_SUCCESS: '@sessoes/GET_SUCCESS',
  GET_FAILURE: '@sessoes/GET_FAILURE',
  SAVE_REQUEST: '@sessoes/SAVE_REQUEST',
  SAVE_SUCCESS: '@sessoes/SAVE_SUCCESS',
  SAVE_FAILURE: '@sessoes/SAVE_FAILURE',
  LIST_REQUEST: '@sessoes/LIST_REQUEST',
  LIST_SUCCESS: '@sessoes/LIST_SUCCESS',
  LIST_FAILURE: '@sessoes/LIST_FAILURE',
  RESET_REQUEST: '@sessoes/RESET_REQUEST',
  LIST_PAST_REQUEST: '@sessoes/LIST_PAST_REQUEST',
  LIST_PAST_SUCCESS: '@sessoes/LIST_PAST_SUCCESS',
  LIST_PAST_FAILURE: '@sessoes/LIST_PAST_FAILURE',
  LIST_FUTURE_REQUEST: '@sessoes/LIST_FUTURE_REQUEST',
  LIST_FUTURE_SUCCESS: '@sessoes/LIST_FUTURE_SUCESSS',
  LIST_FUTURE_FAILURE: '@sessoes/LIST_FUTURE_FAILURE',
  CLEAR_MESSAGES_REQUEST: '@sessoes/CLEAR_MESSAGES_REQUEST',
  SCORE_PRESENCE_REQUEST: '@sessoes/SCORE_PRESENCE_REQUEST',
  SCORE_PRESENCE_SUCCESS: '@sessoes/SCORE_PRESENCE_SUCCESS',
  SCORE_PRESENCE_FAILURE: '@sessoes/SCORE_PRESENCE_FAILURE',
  GET_SESSION_INCOME_REQUEST: '@sessoes/GET_SESSION_INCOME_REQUEST',
  GET_SESSION_INCOME_SUCCESS: '@sessoes/GET_SESSION_INCOME_SUCCESS',
  GET_SESSION_INCOME_FAILURE: '@sessoes/GET_SESSION_INCOME_FAILURE',
  SAVE_DETAIL_ABSENT_REQUEST: '@sessoes/SAVE_DETAIL_ABSENT_REQUEST',
  SAVE_DETAIL_ABSENT_SUCCESS: '@sessoes/SAVE_DETAIL_ABSENT_SUCCESS',
  SAVE_DETAIL_ABSENT_FAILURE: '@sessoes/SAVE_DETAIL_ABSENT_FAILURE',
  RESET_DETAIL_ABSENT_REQUEST: '@sessoes/RESET_DETAIL_ABSENT_REQUEST',
  GET_SESSION_SCHEDULE_EVENT_REQUEST:
    '@sessoes/GET_SESSION_SCHEDULE_EVENT_REQUEST',
  GET_SESSION_SCHEDULE_EVENT_SUCCESS:
    '@sessoes/GET_SESSION_SCHEDULE_EVENT_SUCCESS',
  GET_SESSION_SCHEDULE_EVENT_FAILURE:
    '@sessoes/GET_SESSION_SCHEDULE_EVENT_FAILURE',
  CONFIRM_ATTENDANCE_PATIENT_REQUEST:
    '@sessoes/CONFIRM_ATTENDANCE_PATIENT_REQUEST',
  CONFIRM_ATTENDANCE_PATIENT_SUCCESS:
    '@sessoes/CONFIRM_ATTENDANCE_PATIENT_SUCCESS',
  CONFIRM_ATTENDANCE_PATIENT_FAILURE:
    '@sessoes/CONFIRM_ATTENDANCE_PATIENT_FAILURE',
};

/**
 * Reducer
 */
const INITIAL_STATE = {
  ok: null,
  data: [],
  error: null,
  timeunix: '',
  idCliente: 0,
  object: {},
  selected: {},
  search: {
    offsetPast: 0,
    offsetFuture: 0,
    criteria: '',
  },
  objSessao: {},
  objScoreSessao: {},
  objDetalheAusencia: {},
  objConfirmaPresenca: {},
};

export default function sessoes(state = INITIAL_STATE, action) {
  const newState = { ...state };
  switch (action.type) {
    case Types.LIST_REQUEST:
      return { ...state, idCliente: action.payload.idCliente };

    case Types.LIST_SUCCESS:
      return {
        ...state,
        error: null,
        data: [...action.payload.data],
      };

    case Types.SAVE_REQUEST:
      return { ...state, error: null, objSessao: action.payload.objSessao };

    case Types.RESET_REQUEST:
      return {
        ...state,
        data: [],
        error: null,
        search: {
          offsetPast: 0,
          offsetFuture: 0,
          criteria: '',
        },
      };

    case Types.CLEAR_MESSAGES_REQUEST:
      return { ...state, error: null, ok: null };

    case Types.LIST_FUTURE_REQUEST:
      newState.search.offsetFuture = action.payload.offsetFuture;
      return newState;

    case Types.LIST_FUTURE_SUCCESS:
      return {
        ...state,
        error: null,
        data: [...action.payload.data, ...state.data],
        search: {
          ...newState.search,
          offsetFuture: newState.search.offsetFuture + 7,
        },
      };

    case Types.LIST_PAST_REQUEST:
      newState.selected = {};
      newState.objScoreSessao = {};
      newState.search.offsetPast = action.payload.offsetPast;
      return newState;

    case Types.LIST_PAST_SUCCESS:
      return {
        ...state,
        error: null,
        data: [...state.data, ...action.payload.data],
        search: {
          ...newState.search,
          offsetPast: newState.search.offsetPast + 7,
        },
      };

    case Types.GET_REQUEST:
    case Types.GET_SESSION_INCOME_REQUEST:
    case Types.GET_SESSION_SCHEDULE_EVENT_REQUEST:
      return { ...state, timeunix: action.payload.timeunix };

    case Types.GET_SUCCESS:
      return { ...state, error: null, object: action.payload.object };

    case Types.GET_SESSION_INCOME_SUCCESS:
    case Types.GET_SESSION_SCHEDULE_EVENT_SUCCESS:
      return { ...state, error: null, selected: action.payload.selected };

    case Types.GET_FAILURE:
    case Types.SAVE_FAILURE:
    case Types.LIST_FAILURE:
    case Types.LIST_PAST_FAILURE:
    case Types.LIST_FUTURE_FAILURE:
    case Types.SCORE_PRESENCE_FAILURE:
    case Types.GET_SESSION_INCOME_FAILURE:
    case Types.SAVE_DETAIL_ABSENT_FAILURE:
    case Types.GET_SESSION_SCHEDULE_EVENT_FAILURE:
    case Types.CONFIRM_ATTENDANCE_PATIENT_FAILURE:
      return { ...state, error: action.payload.error };

    case Types.SAVE_DETAIL_ABSENT_REQUEST:
      return {
        ...state,
        objDetalheAusencia: action.payload.objDetalheAusencia,
      };

    case Types.CONFIRM_ATTENDANCE_PATIENT_REQUEST:
      return {
        ...state,
        objConfirmaPresenca: action.payload.objConfirmaPresenca,
      };

    case Types.SCORE_PRESENCE_REQUEST:
      return { ...state, objScoreSessao: action.payload.objScoreSessao };

    case Types.SAVE_SUCCESS:
    case Types.SCORE_PRESENCE_SUCCESS:
    case Types.SAVE_DETAIL_ABSENT_SUCCESS:
    case Types.CONFIRM_ATTENDANCE_PATIENT_SUCCESS:
      return { ...state, error: null, ok: action.payload.ok };

    case Types.RESET_DETAIL_ABSENT_REQUEST:
      return {
        ...state,
        error: null,
        ok: null,
        objDetalheAusencia: {},
      };

    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  listSessoesRequest: idCliente => ({
    type: Types.LIST_REQUEST,
    payload: { idCliente },
  }),

  listSessoesSuccess: data => ({
    type: Types.LIST_SUCCESS,
    payload: { data },
  }),

  listSessoesFailure: error => ({
    type: Types.LIST_FAILURE,
    payload: { error },
  }),

  resetSessoesRequest: () => ({
    type: Types.RESET_REQUEST,
  }),

  listSessoesFuturasRequest: offsetFuture => ({
    type: Types.LIST_FUTURE_REQUEST,
    payload: { offsetFuture },
  }),

  listSessoesFuturasSuccess: data => ({
    type: Types.LIST_FUTURE_SUCCESS,
    payload: { data },
  }),

  listSessoesFuturasFailure: error => ({
    type: Types.LIST_FUTURE_FAILURE,
    payload: { error },
  }),

  listSessoesPassadasRequest: offsetPast => ({
    type: Types.LIST_PAST_REQUEST,
    payload: { offsetPast },
  }),

  listSessoesPassadasSuccess: data => ({
    type: Types.LIST_PAST_SUCCESS,
    payload: { data },
  }),

  listSessoesPassadasFailure: error => ({
    type: Types.LIST_FUTURE_FAILURE,
    payload: { error },
  }),

  getSessaoRequest: timeunix => ({
    type: Types.GET_REQUEST,
    payload: { timeunix },
  }),

  getSessaoSuccess: object => ({
    type: Types.GET_SUCCESS,
    payload: { object },
  }),

  getSessaoFailure: error => ({
    type: Types.GET_FAILURE,
    payload: { error },
  }),

  scorePresencaSessaoRequest: objScoreSessao => ({
    type: Types.SCORE_PRESENCE_REQUEST,
    payload: { objScoreSessao },
  }),

  scorePresencaSessaoSuccess: ok => ({
    type: Types.SCORE_PRESENCE_SUCCESS,
    payload: { ok },
  }),

  scorePresencaSessaoFailure: error => ({
    type: Types.SCORE_PRESENCE_FAILURE,
    payload: { error },
  }),

  getSessaoReceitaRequest: timeunix => ({
    type: Types.GET_SESSION_INCOME_REQUEST,
    payload: { timeunix },
  }),

  getSessaoReceitaSuccess: selected => ({
    type: Types.GET_SESSION_INCOME_SUCCESS,
    payload: { selected },
  }),

  getSessaoReceitaFailure: error => ({
    type: Types.GET_SESSION_INCOME_FAILURE,
    payload: { error },
  }),

  getSessaoAgendaEventoRequest: timeunix => ({
    type: Types.GET_SESSION_SCHEDULE_EVENT_REQUEST,
    payload: { timeunix },
  }),

  getSessaoAgendaEventoSuccess: selected => ({
    type: Types.GET_SESSION_SCHEDULE_EVENT_SUCCESS,
    payload: { selected },
  }),

  getSessaoAgendaEventoFailure: error => ({
    type: Types.GET_SESSION_SCHEDULE_EVENT_FAILURE,
    payload: { error },
  }),

  resetDetalheAusenciaRequest: () => ({
    type: Types.RESET_DETAIL_ABSENT_REQUEST,
  }),

  saveDetalheAusenciaRequest: objDetalheAusencia => ({
    type: Types.SAVE_DETAIL_ABSENT_REQUEST,
    payload: { objDetalheAusencia },
  }),

  saveDetalheAusenciaSuccess: ok => ({
    type: Types.SAVE_DETAIL_ABSENT_SUCCESS,
    payload: { ok },
  }),

  saveDetalheAusenciaFailure: error => ({
    type: Types.SAVE_DETAIL_ABSENT_FAILURE,
    payload: { error },
  }),

  saveSessaoRequest: objSessao => ({
    type: Types.SAVE_REQUEST,
    payload: { objSessao },
  }),

  saveSessaoSuccess: ok => ({
    type: Types.SAVE_SUCCESS,
    payload: { ok },
  }),

  saveSessaoFailure: error => ({
    type: Types.SAVE_FAILURE,
    payload: { error },
  }),

  confirmPresencaSessaoRequest: objConfirmaPresenca => ({
    type: Types.CONFIRM_ATTENDANCE_PATIENT_REQUEST,
    payload: { objConfirmaPresenca },
  }),

  confirmPresencaSessaoSuccess: ok => ({
    type: Types.CONFIRM_ATTENDANCE_PATIENT_SUCCESS,
    payload: { ok },
  }),

  confirmPresencaSessaoFailure: error => ({
    type: Types.CONFIRM_ATTENDANCE_PATIENT_FAILURE,
    payload: { error },
  }),

  clearMessagesRequest: () => ({
    type: Types.CLEAR_MESSAGES_REQUEST,
  }),
};
