import axios from 'axios';

import Config from '../utils/config';

import { getToken, logout } from './storage';

const API = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? Config.URL_API_DEVELOPMENT
      : Config.URL_API_PRODUCTION,
});

const noToken = ['/psico-auth', '/psico-auth/registrar'];
API.interceptors.request.use(async config => {
  const cfg = config;
  if (!noToken.includes(cfg.url)) {
    const token = getToken();
    cfg.headers.Authorization = `Bearer ${token}`;
  }
  return cfg;
});

/** Verificando a expiração do TOKEN para limpar storage e redirecionar para o LOGIN. */
API.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 401) {
      setTimeout(() => {
        logout();
      }, 500);
    }
    return Promise.reject(error);
  }
);

export default API;
