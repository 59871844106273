/**
 * Types
 */
export const Types = {
  GET_CONFIG_EMPRESA_REQUEST: '@empresa/GET_CONFIG_EMPRESA_REQUEST',
  GET_CONFIG_EMPRESA_SUCCESS: '@empresa/GET_CONFIG_EMPRESA_SUCCESS',
  GET_CONFIG_EMPRESA_FAILURE: '@empresa/GET_CONFIG_EMPRESA_FAILURE',
  CHECK_NUMERO_PIN_EMPRESA_REQUEST: '@empresa/CHECK_NUMERO_PIN_EMPRESA_REQUEST',
  CHECK_NUMERO_PIN_EMPRESA_SUCCESS: '@empresa/CHECK_NUMERO_PIN_EMPRESA_SUCCESS',
  CHECK_NUMERO_PIN_EMPRESA_FAILURE: '@empresa/CHECK_NUMERO_PIN_EMPRESA_FAILURE',
  RELEASE_NUMERO_PIN_EMPRESA_REQUEST:
    '@empresa/RELEASE_NUMERO_PIN_EMPRESA_REQUEST',
  RELEASE_NUMERO_PIN_EMPRESA_SUCCESS:
    '@empresa/RELEASE_NUMERO_PIN_EMPRESA_SUCCESS',
  RELEASE_NUMERO_PIN_EMPRESA_FAILURE:
    '@empresa/RELEASE_NUMERO_PIN_EMPRESA_FAILURE',
};

/**
 * Reducer
 */
const INITIAL_STATE = {
  config: {},
  rowbody: {},
  hasNumeroPin: false,
  isReleasedPin: false,
  hasErrorConfig: false,
};

export default function empresa(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_CONFIG_EMPRESA_REQUEST:
    case Types.CHECK_NUMERO_PIN_EMPRESA_REQUEST:
      return { ...state };

    case Types.CHECK_NUMERO_PIN_EMPRESA_SUCCESS:
      return { ...state, hasNumeroPin: action.payload.hasNumeroPin };

    case Types.CHECK_NUMERO_PIN_EMPRESA_FAILURE:
      return { ...state, hasNumeroPin: action.payload.hasNumeroPin };

    case Types.RELEASE_NUMERO_PIN_EMPRESA_REQUEST:
      return { ...state, rowbody: action.payload.rowbody };

    case Types.RELEASE_NUMERO_PIN_EMPRESA_SUCCESS:
    case Types.RELEASE_NUMERO_PIN_EMPRESA_FAILURE:
      return { ...state, isReleasedPin: action.payload.isReleasedPin };

    case Types.GET_CONFIG_EMPRESA_FAILURE:
      return { ...state, hasErrorConfig: action.payload.hasErrorConfig };

    case Types.GET_CONFIG_EMPRESA_SUCCESS:
      return { ...state, hasErrorConfig: false, config: action.payload.config };

    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  checkNumeroPinEmpresaRequest: () => ({
    type: Types.CHECK_NUMERO_PIN_EMPRESA_REQUEST,
  }),

  checkNumeroPinEmpresaSuccess: hasNumeroPin => ({
    type: Types.CHECK_NUMERO_PIN_EMPRESA_SUCCESS,
    payload: { hasNumeroPin },
  }),

  checkNumeroPinEmpresaFailure: hasNumeroPin => ({
    type: Types.CHECK_NUMERO_PIN_EMPRESA_FAILURE,
    payload: { hasNumeroPin },
  }),

  releaseNumeroPinRequest: rowbody => ({
    type: Types.RELEASE_NUMERO_PIN_EMPRESA_REQUEST,
    payload: { rowbody },
  }),

  releaseNumeroPinSuccess: isReleasedPin => ({
    type: Types.RELEASE_NUMERO_PIN_EMPRESA_SUCCESS,
    payload: { isReleasedPin },
  }),

  releaseNumeroPinFailure: isReleasedPin => ({
    type: Types.RELEASE_NUMERO_PIN_EMPRESA_FAILURE,
    payload: { isReleasedPin },
  }),

  getConfigEmpresaRequest: () => ({
    type: Types.GET_CONFIG_EMPRESA_REQUEST,
  }),

  getConfigEmpresaSuccess: config => ({
    type: Types.GET_CONFIG_EMPRESA_SUCCESS,
    payload: { config },
  }),

  getConfigEmpresaFailure: hasErrorConfig => ({
    type: Types.GET_CONFIG_EMPRESA_FAILURE,
    payload: { hasErrorConfig },
  }),
};
