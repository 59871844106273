import { call, put } from 'redux-saga/effects';

import { Creators as HorarioActions } from '../ducks/horarios';

import API from '../../services/api';

export function* listarHorariosCliente(action) {
  try {
    const { payload } = action;
    const { data } = yield call(
      API.get,
      `/sessoes/horario-mob/?q=(cod_paciente:${payload.idCliente})`
    );

    if (!data.records.length) {
      yield put(
        HorarioActions.listHorariosFailure(
          'Nenhum horário cadastrado até o momento!'
        )
      );
    } else {
      const { records } = data;
      yield put(HorarioActions.listHorariosSuccess(records));
    }
  } catch (err) {
    yield put(
      HorarioActions.listHorariosFailure(
        'Erro interno no módulo de listagem de horários!'
      )
    );
  }
}

export function* saveHorario(action) {
  try {
    const { payload } = action;

    let response = null;
    if (payload.object.time_unix) {
      const codEvento = `${payload.object.cod_empresa}|${payload.object.time_unix}`;

      response = yield call(
        API.put,
        `/sessao/evento/${codEvento}`,
        payload.object
      );
    } else {
      response = yield call(API.post, '/sessao/evento', payload.object);
    }

    const { data } = response;
    if (data.records.error) {
      yield put(HorarioActions.saveHorarioFailure(data.records.msg));
    } else {
      yield put(
        HorarioActions.saveHorarioSuccess('Horário salvo com sucesso! =)')
      );
    }
  } catch (err) {
    yield put(
      HorarioActions.saveHorarioFailure(
        'Erro interno no módulo de salvar os dados de horário!'
      )
    );
  }
}
