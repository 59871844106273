import { call, put } from 'redux-saga/effects';

import { Creators as AnamneseActions } from '../ducks/anamneses';

import API from '../../services/api';

export function* listarAnamneses() {
  try {
    const { data } = yield call(API.get, '/empresa/grupo-anamnese');
    if (!data.records.length) {
      yield put(
        AnamneseActions.listAnamnesesFailure(
          'Nenhuma anamnese cadastrada até o momento!'
        )
      );
    } else {
      const { records } = data;
      yield put(AnamneseActions.listAnamnesesSuccess(records));
    }
  } catch (err) {
    yield put(
      AnamneseActions.listAnamnesesFailure(
        'Erro interno no módulo de listagem de anamneses!'
      )
    );
  }
}

export function* getGrupoAnamneseByPaciente(action) {
  try {
    const { payload } = action;
    const { data } = yield call(
      API.get,
      `/paciente/anamnese/${payload.idPaciente}`
    );
    if (data.records.error) {
      yield put(
        AnamneseActions.getGrupoAnamneseFailure(
          'Grupo de anamnese inexistente para o usuário em questão!'
        )
      );
    } else {
      yield put(AnamneseActions.getGrupoAnamneseSuccess(data.records.anamnese));
    }
  } catch (err) {
    yield put(
      AnamneseActions.getGrupoAnamneseFailure(
        'Erro interno no módulo de recolher os dados de uma anamnese de um determinado paciente!'
      )
    );
  }
}

export function* atualizarGrupoAnamnese(action) {
  try {
    const { payload } = action;
    const { data } = yield call(
      API.put,
      `/paciente/anamnese/`,
      payload.rowbody
    );

    const { records } = data;
    if (records.error) {
      yield put(AnamneseActions.saveGrupoAnamneseFailure(records.msg));
    } else {
      yield put(AnamneseActions.saveGrupoAnamneseSuccess(records.msg));
    }
  } catch (err) {
    yield put(
      AnamneseActions.saveGrupoAnamneseFailure(
        'Erro interno no módulo de atualizar os dados da anamnese!'
      )
    );
  }
}
