/**
 * Types
 */
export const Types = {
  SAVE_REQUEST: '@financas/SAVE_REQUEST',
  SAVE_SUCCESS: '@financas/SAVE_SUCCESS',
  SAVE_FAILURE: '@financas/SAVE_FAILURE',
  SAVE_MONTHLY_REQUEST: '@financas/SAVE_MONTHLY_REQUEST',
  SAVE_MONTHLY_SUCCESS: '@financas/SAVE_MONTHLY_SUCCESS',
  SAVE_MONTHLY_FAILURE: '@financas/SAVE_MONTHLY_FAILURE',
  SAVE_FINANCIAL_REQUEST: '@financas/SAVE_FINANCIAL_REQUEST',
  SAVE_FINANCIAL_SUCCESS: '@financas/SAVE_FINANCIAL_SUCCESS',
  SAVE_FINANCIAL_FAILURE: '@financas/SAVE_FINANCIAL_FAILURE',
  GET_INCOME_OR_EXPENSE_REQUEST: '@financas/GET_INCOME_OR_EXPENSE_REQUEST',
  GET_INCOME_OR_EXPENSE_SUCCESS: '@financas/GET_INCOME_OR_EXPENSE_SUCCESS',
  GET_INCOME_OR_EXPENSE_FAILURE: '@financas/GET_INCOME_OR_EXPENSE_FAILURE',
  LIST_FINANCIAL_INCOME_REQUEST: '@financas/LIST_FINANCIAL_INCOME_REQUEST',
  LIST_FINANCIAL_INCOME_SUCCESS: '@financas/LIST_FINANCIAL_INCOME_SUCCESS',
  LIST_FINANCIAL_INCOME_FAILURE: '@financas/LIST_FINANCIAL_INCOME_FAILURE',
  LIST_ACCOUNTS_RECEIVE_REQUEST: '@financas/LIST_ACCOUNTS_RECEIVE_REQUEST',
  LIST_ACCOUNTS_RECEIVE_SUCCESS: '@financas/LIST_ACCOUNTS_RECEIVE_SUCCESS',
  LIST_ACCOUNTS_RECEIVE_FAILURE: '@financas/LIST_ACCOUNTS_RECEIVE_FAILURE',
  SET_PERMISSION_ACCOUNTS_NORMAL: '@financas/SET_PERMISSION_ACCOUNTS_NORMAL',
  GET_TOTAL_ACCOUNTS_RECEIVE_REQUEST:
    '@financas/GET_TOTAL_ACCOUNTS_RECEIVE_REQUEST',
  GET_TOTAL_ACCOUNTS_RECEIVE_SUCCESS:
    '@financas/GET_TOTAL_ACCOUNTS_RECEIVE_SUCCESS',
  GET_TOTAL_ACCOUNTS_RECEIVE_FAILURE:
    '@financas/GET_TOTAL_ACCOUNTS_RECEIVE_FAILURE',
};

/**
 * Reducer
 */
const INITIAL_STATE = {
  ok: null,
  error: null,
  data: [],
  total: {},
  query: '',
  object: {},
  timeunix: '',
  idReceita: 0,
  permission: true,
  jsonAccounts: {},
  objectFinancial: {},
  objReceitaDespesa: {},
  objFiltroReceitaDespesa: {},
};

export default function financas(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LIST_FINANCIAL_INCOME_REQUEST:
    case Types.GET_TOTAL_ACCOUNTS_RECEIVE_REQUEST:
      return { ...state, query: action.payload.query };

    case Types.SAVE_FINANCIAL_REQUEST:
      return { ...state, objectFinancial: action.payload.objectFinancial };

    case Types.LIST_FINANCIAL_INCOME_SUCCESS:
      return {
        ...state,
        error: null,
        data: [...action.payload.data],
      };

    case Types.GET_TOTAL_ACCOUNTS_RECEIVE_SUCCESS:
      return {
        ...state,
        total: action.payload.total,
      };

    case Types.SAVE_FAILURE:
    case Types.SAVE_MONTHLY_FAILURE:
    case Types.SAVE_FINANCIAL_FAILURE:
    case Types.LIST_FINANCIAL_INCOME_FAILURE:
    case Types.LIST_ACCOUNTS_RECEIVE_FAILURE:
    case Types.GET_INCOME_OR_EXPENSE_FAILURE:
    case Types.GET_TOTAL_ACCOUNTS_RECEIVE_FAILURE:
      return {
        ...state,
        ok: null,
        error: action.payload.error,
        data: [],
      };

    case Types.LIST_ACCOUNTS_RECEIVE_REQUEST:
      return { ...state, idReceita: action.payload.idReceita };

    case Types.GET_INCOME_OR_EXPENSE_REQUEST:
      return {
        ...state,
        objFiltroReceitaDespesa: action.payload.objFiltroReceitaDespesa,
      };

    case Types.GET_INCOME_OR_EXPENSE_SUCCESS:
      return {
        ...state,
        objReceitaDespesa: action.payload.objReceitaDespesa,
      };

    case Types.LIST_ACCOUNTS_RECEIVE_SUCCESS:
      return {
        ...state,
        error: null,
        jsonAccounts: action.payload.jsonAccounts,
      };

    case Types.SAVE_REQUEST:
    case Types.SAVE_MONTHLY_REQUEST:
      return { ...state, object: action.payload.object };

    case Types.SAVE_SUCCESS:
    case Types.SAVE_MONTHLY_SUCCESS:
    case Types.SAVE_FINANCIAL_SUCCESS:
      return { ...state, error: null, ok: action.payload.ok };

    case Types.SET_PERMISSION_ACCOUNTS_NORMAL:
      return { ...state, permission: action.payload.permission };

    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  listReceitasFinanceirasRequest: query => ({
    type: Types.LIST_FINANCIAL_INCOME_REQUEST,
    payload: { query },
  }),

  listReceitasFinanceirasSuccess: data => ({
    type: Types.LIST_FINANCIAL_INCOME_SUCCESS,
    payload: { data },
  }),

  listReceitasFinanceirasFailure: error => ({
    type: Types.LIST_FINANCIAL_INCOME_FAILURE,
    payload: { error },
  }),

  listContasReceberSessaoRequest: idReceita => ({
    type: Types.LIST_ACCOUNTS_RECEIVE_REQUEST,
    payload: { idReceita },
  }),

  listContasReceberSessaoSuccess: jsonAccounts => ({
    type: Types.LIST_ACCOUNTS_RECEIVE_SUCCESS,
    payload: { jsonAccounts },
  }),

  listContasReceberSessaoFailure: error => ({
    type: Types.LIST_ACCOUNTS_RECEIVE_FAILURE,
    payload: { error },
  }),

  saveReceitaDespesaRequest: object => ({
    type: Types.SAVE_REQUEST,
    payload: { object },
  }),

  saveReceitaDespesaMensalRequest: object => ({
    type: Types.SAVE_MONTHLY_REQUEST,
    payload: { object },
  }),

  saveReceitaDespesaSuccess: ok => ({
    type: Types.SAVE_SUCCESS,
    payload: { ok },
  }),

  saveReceitaDespesaMensalSuccess: ok => ({
    type: Types.SAVE_MONTHLY_SUCCESS,
    payload: { ok },
  }),

  saveReceitaDespesaFailure: error => ({
    type: Types.SAVE_FAILURE,
    payload: { error },
  }),

  saveReceitaDespesaMensalFailure: error => ({
    type: Types.SAVE_MONTHLY_FAILURE,
    payload: { error },
  }),

  getReceitaOuDespesaRequest: objFiltroReceitaDespesa => ({
    type: Types.GET_INCOME_OR_EXPENSE_REQUEST,
    payload: { objFiltroReceitaDespesa },
  }),

  getReceitaOuDespesaSuccess: objReceitaDespesa => ({
    type: Types.GET_INCOME_OR_EXPENSE_SUCCESS,
    payload: { objReceitaDespesa },
  }),

  getReceitaOuDespesaFailure: error => ({
    type: Types.GET_INCOME_OR_EXPENSE_FAILURE,
    payload: { error },
  }),

  saveFinanceiroRequest: objectFinancial => ({
    type: Types.SAVE_FINANCIAL_REQUEST,
    payload: { objectFinancial },
  }),

  saveFinanceiroSuccess: ok => ({
    type: Types.SAVE_FINANCIAL_SUCCESS,
    payload: { ok },
  }),

  saveFinanceiroFailure: error => ({
    type: Types.SAVE_FINANCIAL_FAILURE,
    payload: { error },
  }),

  totalReceitasFinanceirasRequest: query => ({
    type: Types.GET_TOTAL_ACCOUNTS_RECEIVE_REQUEST,
    payload: { query },
  }),

  totalReceitasFinanceirasSuccess: total => ({
    type: Types.GET_TOTAL_ACCOUNTS_RECEIVE_SUCCESS,
    payload: { total },
  }),

  totalReceitasFinanceirasFailure: error => ({
    type: Types.GET_TOTAL_ACCOUNTS_RECEIVE_FAILURE,
    payload: { error },
  }),

  setPermissaoFinancasNormal: permission => ({
    type: Types.SET_PERMISSION_ACCOUNTS_NORMAL,
    payload: { permission },
  }),
};
