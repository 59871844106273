/**
 * Types
 */
export const Types = {
  LIST_REQUEST: '@horarios/LIST_REQUEST',
  LIST_SUCCESS: '@horarios/LIST_SUCCESS',
  LIST_FAILURE: '@horarios/LIST_FAILURE',
  SAVE_REQUEST: '@horarios/SAVE_REQUEST',
  SAVE_SUCCESS: '@horarios/SAVE_SUCCESS',
  SAVE_FAILURE: '@horarios/SAVE_FAILURE',
  RESET_REQUEST: '@horarios/RESET_REQUEST',
};

/**
 * Reducer
 */
const INITIAL_STATE = {
  data: [],
  error: null,
  object: {},
  idCliente: 0,
};

export default function horarios(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_REQUEST:
      return { ...state, data: [], error: null };
    case Types.LIST_REQUEST:
      return { ...state, idCliente: action.payload.idCliente };
    case Types.LIST_SUCCESS:
      return {
        ...state,
        ok: null,
        error: null,
        data: [...action.payload.data],
      };
    case Types.LIST_FAILURE:
      return { ...state, error: action.payload.error };
    case Types.SAVE_REQUEST:
      return { ...state, object: action.payload.object };
    case Types.SAVE_SUCCESS:
      return { ...state, error: null, ok: action.payload.ok };
    case Types.SAVE_FAILURE:
      return { ...state, ok: null, error: action.payload.error };
    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  listHorariosRequest: idCliente => ({
    type: Types.LIST_REQUEST,
    payload: { idCliente },
  }),

  listHorariosSuccess: data => ({
    type: Types.LIST_SUCCESS,
    payload: { data },
  }),

  listHorariosFailure: error => ({
    type: Types.LIST_FAILURE,
    payload: { error },
  }),

  resetHorariosRequest: () => ({
    type: Types.RESET_REQUEST,
  }),

  saveHorarioRequest: object => ({
    type: Types.SAVE_REQUEST,
    payload: { object },
  }),

  saveHorarioSuccess: ok => ({
    type: Types.SAVE_SUCCESS,
    payload: { ok },
  }),

  saveHorarioFailure: error => ({
    type: Types.SAVE_FAILURE,
    payload: { error },
  }),
};
