/* eslint-disable global-require */

import React, { Component } from 'react';

import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';

import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';

import './App.scss';

import { blue, pink } from '@material-ui/core/colors';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import Store from './store';
import Loading from './components/Loading';
import Offline from './components/Offline';
import Constants from './utils/constants';

import { getStorage, isAuthenticated } from './services/storage';

const locales = {
  'es-ES': require('./locales/es-ES.json'),
  'en-US': require('./locales/en-US.json'),
  'pt-BR': require('./locales/pt-BR.json'),
};

const theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: pink,
  },
});

const Login = React.lazy(() => import('./views/Pages/Login'));
const Cadastro = React.lazy(() => import('./views/Pages/Cadastro'));
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

const PrivateRoute = ({ component: ComponentTwo, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <ComponentTwo {...props} />
      ) : (
        <Redirect
          to={{ pathname: '/login', state: { from: props.location } }}
        />
      )
    }
  />
);

export default class App extends Component {
  constructor() {
    super();
    this.configLocale();
  }

  configLocale = () => {
    let currentLocale = 'pt-BR';
    const storage = getStorage();

    if (storage) {
      const { lingua } = storage;
      switch (lingua) {
        case Constants.LANGUAGE.EN:
          currentLocale = 'en-US';
          break;
        case Constants.LANGUAGE.ES:
          currentLocale = 'es-ES';
          break;
        case Constants.LANGUAGE.PT:
          currentLocale = 'pt-PT';
          break;
        default:
          currentLocale = 'pt-BR';
          break;
      }
    }

    intl.init({ currentLocale, locales });
  };

  render() {
    return (
      <Provider store={Store}>
        <HashRouter>
          <React.Suspense fallback={Loading()}>
            <MuiThemeProvider theme={theme}>
              <Offline text={intl.get('VOCE_ESTA_OFFLINE')} />
              <Switch>
                <Route
                  exact
                  path="/login"
                  name="Login Page"
                  render={props => <Login {...props} />}
                />
                <Route
                  exact
                  path="/cadastro"
                  name="Cadastro"
                  render={props => <Cadastro {...props} />}
                />
                <PrivateRoute
                  path="/"
                  name="Home"
                  component={props => <DefaultLayout {...props} />}
                />
              </Switch>
            </MuiThemeProvider>
          </React.Suspense>
        </HashRouter>
      </Provider>
    );
  }
}

PrivateRoute.propTypes = {
  component: PropTypes.func,
  location: PropTypes.shape({}),
};

PrivateRoute.defaultProps = {
  component: () => {},
  location: {},
};
