/**
 * Types
 */
export const Types = {
  LIST_REQUEST: '@anotacoes/LIST_REQUEST',
  LIST_SUCCESS: '@anotacoes/LIST_SUCCESS',
  LIST_FAILURE: '@anotacoes/LIST_FAILURE',
  RESET_REQUEST: '@anotacoes/RESET_REQUEST',
};

/**
 * Reducer
 */
const INITIAL_STATE = {
  data: [],
  error: null,
  object: {},
  idCliente: 0,
};

export default function anotacoes(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_REQUEST:
      return { ...state, data: [], error: null };
    case Types.LIST_REQUEST:
      return { ...state, idCliente: action.payload.idCliente };
    case Types.LIST_SUCCESS:
      return {
        ...state,
        ok: null,
        error: null,
        data: [...action.payload.data],
      };
    case Types.LIST_FAILURE:
      return { ...state, error: action.payload.error };
    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  listAnotacoesRequest: idCliente => ({
    type: Types.LIST_REQUEST,
    payload: { idCliente },
  }),

  listAnotacoesSuccess: data => ({
    type: Types.LIST_SUCCESS,
    payload: { data },
  }),

  listAnotacoesFailure: error => ({
    type: Types.LIST_FAILURE,
    payload: { error },
  }),

  resetAnotacoesRequest: () => ({
    type: Types.RESET_REQUEST,
  }),
};
