/* eslint-disable no-unused-vars */
/* eslint-disable global-require */

import moment from 'moment';

import { es, enUS, ptBR } from 'date-fns/locale';

import PhoneEnglish from 'react-phone-number-input/locale/en';
import PhoneSpanish from 'react-phone-number-input/locale/es';
import PhonePortuguese from 'react-phone-number-input/locale/br';

import Constants from './constants';
import { getStorage } from '../services/storage';

export default {
  convertDateToCalendarComponent(date, delimiter) {
    if (date) {
      const arrDate = date.split(delimiter || '/');
      return new Date(
        `${arrDate[0]}-${arrDate[1]}-${arrDate[2]}`.replace(
          /(\d{2})-(\d{2})-(\d{4})/,
          '$2/$1/$3'
        )
      );
    }
    return null;
  },

  sumMinutesInDate(date, minutes) {
    date.setMinutes(date.getMinutes() + parseInt(minutes || 0, 10));
    return date;
  },

  capitalize(string) {
    if (typeof string !== 'string') return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  },

  cloneObject(object) {
    const objCopy = {};

    Object.keys(object).map(valor => {
      objCopy[valor] = object[valor];
      return true;
    });

    return objCopy;
  },

  inArray(needle, haystack) {
    if (haystack) {
      const lengthArray = haystack.length;
      for (let i = 0; i < lengthArray; i += 1) {
        if (haystack[i] === needle) return true;
      }
    }
    return false;
  },

  isValidString(string) {
    if (
      string === '' ||
      string === null ||
      string === undefined ||
      string === ' '
    ) {
      return false;
    }
    return true;
  },

  isEmptyObject(object) {
    if (Object.keys(object).length === 0 && object.constructor === Object) {
      return true;
    }
    return false;
  },

  stripTags(text) {
    if (text === null || text === '') {
      return '';
    }
    const retorno = this.htmlentitiesDecode(text.toString());
    return retorno.replace(/<[^>]*>/g, '');
  },

  htmlentitiesDecode(text) {
    const retorno = document.createElement('textarea');
    retorno.innerHTML = text;
    return retorno.value;
  },

  htmlentitiesEncode(text) {
    const buf = [];
    for (let i = text.length - 1; i >= 0; i -= 1) {
      buf.unshift(['&#', text[i].charCodeAt(), ';'].join(''));
    }
    return buf.join('');
  },

  hasSpeechRecognitionInWebBrowser() {
    const SpeechRecognition =
      window.SpeechRecognition ||
      window.webkitSpeechRecognition ||
      window.mozSpeechRecognition ||
      window.msSpeechRecognition ||
      window.oSpeechRecognition;

    return !!SpeechRecognition;
  },

  buildQueryString(objeto, strComplemento) {
    const arrFiltro = [];
    let strFiltro = '';

    // eslint-disable-next-line no-restricted-syntax
    for (const k in objeto) {
      if (objeto[k] || objeto[k] === 0) {
        if (typeof objeto[k] === 'object') {
          if (objeto[k].length) {
            arrFiltro.push(`${k}:${objeto[k].join('')}`);
          }
        } else {
          arrFiltro.push(`${k}:${objeto[k]}`);
        }
      }
    }

    if (arrFiltro.length) {
      strFiltro = `?q=(${arrFiltro.join(',')})`;
    }

    if (strComplemento !== '' && strComplemento !== undefined) {
      strFiltro =
        strFiltro === '' ? strComplemento : `${strFiltro}&${strComplemento}`;
    }

    return strFiltro;
  },

  getDdiFromPhoneNumber(phone) {
    if (phone) {
      const arrPhone = phone.split(' ');
      return arrPhone[0];
    }
    return null;
  },

  getIsoCodeDefaultByCountry() {
    const storage = getStorage();
    const arrIsoCountry = Constants.ISO_COUNTRY;
    if (storage) {
      const pais = storage.cod_pais;
      if (arrIsoCountry[parseInt(pais, 10)]) {
        return arrIsoCountry[parseInt(pais, 10)];
      }
    }
    return arrIsoCountry[0];
  },

  getDdiDefaultByCountry() {
    const storage = getStorage();
    const arrDdi = Constants.DDI;
    if (storage) {
      const pais = storage.cod_pais;
      if (arrDdi[parseInt(pais, 10)]) {
        return arrDdi[parseInt(pais, 10)];
      }
    }
    return arrDdi[0];
  },

  formatDateToView(date, concatHour) {
    if (date) {
      const arrDateTime = date.split(' ');
      if (arrDateTime.length > 0) {
        const arrDate = arrDateTime[0].split('-');

        const hourFormat = arrDateTime[1];
        const dateFormat = `${arrDate[2]}/${arrDate[1]}/${arrDate[0]}${
          concatHour ? ` ${hourFormat}` : ''
        }`;

        return dateFormat;
      }
    }
    return null;
  },

  /**
   * new Date(year, month, day, hours, minutes, seconds, milliseconds);
   * @param {*} date
   */
  getDateToCalendar(date) {
    if (date) {
      const arrDateTime = date.trim().split(' ');
      if (arrDateTime.length > 0) {
        const arrDate = arrDateTime[0].split('-');
        const arrHour = arrDateTime[1].split(':');
        if (arrDate.length > 0 && arrHour.length > 0) {
          const result = new Date(
            parseInt(arrDate[0], 10),
            parseInt(arrDate[1], 10) - 1,
            parseInt(arrDate[2], 10),
            parseInt(arrHour[0], 10),
            parseInt(arrHour[1], 10),
            parseInt(arrHour[2], 10)
          );
          return result;
        }
      }
    }
    return null;
  },

  importDateFnsLocale() {
    const storage = getStorage();
    if (storage) {
      const { lingua } = storage;
      switch (lingua) {
        case Constants.LANGUAGE.EN:
          return enUS;
        case Constants.LANGUAGE.ES:
          return es;
        default:
          return ptBR;
      }
    }
    return ptBR;
  },

  importMomentLocale() {
    const storage = getStorage();
    if (storage) {
      const { lingua } = storage;
      switch (lingua) {
        case Constants.LANGUAGE.EN:
          moment.locale('en-au');
          break;
        case Constants.LANGUAGE.ES:
          moment.locale('es');
          break;
        default:
          moment.locale('pt-br');
          break;
      }
    }
  },

  importPhoneNumberLocale() {
    const storage = getStorage();
    if (storage) {
      const { lingua } = storage;
      switch (lingua) {
        case Constants.LANGUAGE.EN:
          return PhoneEnglish;
        case Constants.LANGUAGE.ES:
          return PhoneSpanish;
        default:
          return PhonePortuguese;
      }
    }
    return PhonePortuguese;
  },

  getDateToFormatPicker(date) {
    if (date) {
      let arrDate = date.split('-');
      if (arrDate.length === 1) {
        arrDate = date.split('/');
        return `${arrDate[2]}-${arrDate[1]}-${arrDate[0]}T00:00:00`;
      }
      if (arrDate.length === 3) {
        return `${date}T00:00:00`;
      }
    }
    return null;
  },

  getHoursFromHour(hour, vlrDefault) {
    if (hour) {
      const hourLength = hour.length;
      if (hourLength <= 2) {
        const hourInteger = parseInt(hour, 10);
        if (hourInteger > 23) {
          return vlrDefault;
        }
        return hourInteger;
      }
      const hours = hour.substr(0, 2);
      return parseInt(hours, 10);
    }
    return vlrDefault;
  },

  getMinutesFromHour(hour, vlrDefault) {
    if (hour) {
      const hourLength = hour.length;
      if (hourLength <= 2) {
        return 0;
      }
      const minutes = hour.substr(-2);
      return parseInt(minutes, 10);
    }
    return vlrDefault || 0;
  },

  checkPass(password) {
    if (password.length < 6) {
      return 'A senha deve ter pelo menos 6 caracteres!';
    }
    if (password.length > 50) {
      return 'Senha muito longa!';
    }
    if (password.search(/\d/) === -1) {
      return 'Sua senha deve conter pelo menos 1 número!';
    }
    if (password.search(/[a-zA-Z]/) === -1) {
      return 'Sua senha deve conter pelo menos 1 letra!';
    }
    return 'OK';
  },
};
