import { call, put } from 'redux-saga/effects';

import { Creators as AnotacaoActions } from '../ducks/anotacoes';

import API from '../../services/api';
import Factories from '../../utils';

export function* listarAnotacoes(action) {
  try {
    const { payload } = action;
    const { data } = yield call(
      API.get,
      `/sessoes/anotacoes-mob/?q=(cod_paciente:${payload.idCliente})`
    );

    if (!Array.isArray(data.records)) {
      const arrAnotacoes = [];
      let i = 0;
      Object.keys(data.records).map(valor => {
        if (!Factories.isEmptyObject(data.records[valor])) {
          arrAnotacoes[i] = data.records[valor];
          i += 1;
          console.log(i, valor);
        }
        return true;
      });
      console.log('2: ', arrAnotacoes);
      yield put(AnotacaoActions.listAnotacoesSuccess(arrAnotacoes));
    }

    if (Array.isArray(data.records)) {
      if (!data.records.length) {
        yield put(
          AnotacaoActions.listAnotacoesFailure(
            'Nenhuma anotação cadastrada até o momento!'
          )
        );
      } else {
        const { records } = data;
        console.log('1: ', records);
        yield put(AnotacaoActions.listAnotacoesSuccess(records));
      }
    }
  } catch (err) {
    yield put(
      AnotacaoActions.listAnotacoesFailure(
        'Erro interno no módulo de listagem de anotações!'
      )
    );
  }
}
