import { call, put } from 'redux-saga/effects';

import { Creators as CampoActions } from '../ducks/campos';

import API from '../../services/api';

export function* getCamposFormularioPorServico(action) {
  try {
    const { payload } = action;
    const { data } = yield call(
      API.get,
      `/form-campos/?q=(cod_servico:${
        payload.idServico ? payload.idServico : 0
      })`
    );

    if (data.records) {
      yield put(CampoActions.getCamposFormularioSuccess(data.records));
    } else {
      yield put(
        CampoActions.getCamposFormularioFailure(
          'Nenhum campo cadastrado até o momento!'
        )
      );
    }
  } catch (err) {
    yield put(
      CampoActions.getCamposFormularioFailure(
        'Erro interno no módulo de listagem de campos!'
      )
    );
  }
}
