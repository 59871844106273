/**
 * Types
 */
export const Types = {
  LIST_REQUEST: '@convenios/LIST_REQUEST',
  LIST_SUCCESS: '@convenios/LIST_SUCCESS',
  LIST_FAILURE: '@convenios/LIST_FAILURE',
};

/**
 * Reducer
 */
const INITIAL_STATE = {
  error: null,
  data: [],
};

export default function convenios(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LIST_REQUEST:
      return { ...state };
    case Types.LIST_SUCCESS:
      return {
        ...state,
        error: null,
        data: [...state.data, ...action.payload.data],
      };
    case Types.LIST_FAILURE:
      return { ...state, error: action.payload.error };
    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  listConveniosRequest: () => ({
    type: Types.LIST_REQUEST,
  }),

  listConveniosSuccess: data => ({
    type: Types.LIST_SUCCESS,
    payload: { data },
  }),

  listConveniosFailure: error => ({
    type: Types.LIST_FAILURE,
    payload: { error },
  }),
};
