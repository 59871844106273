import { all, takeLatest } from 'redux-saga/effects';

import { efetuarLogin } from './login';
import { Types as LoginTypes } from '../ducks/login';

import { listarSalas } from './salas';
import { Types as SalaTypes } from '../ducks/salas';

import {
  getSessao,
  listarAgenda,
  anexarAgenda,
  salvarSessao,
  getSessaoReceita,
  salvarEventoAgenda,
  removerEventoAgenda,
  scorePresencaSessao,
  listarSessoesFuturas,
  listarSessoesPassadas,
  salvarDetalheAusencia,
  getSessaoAgendaEvento,
  confirmarPresencaSessao,
  listarSessoesSalasPorData,
} from './sessoes';
import { Types as AgendaTypes } from '../ducks/agendas';
import { Types as SessoesTypes } from '../ducks/sessoes';

import { listarPacotes } from './pacotes';
import { Types as PacoteTypes } from '../ducks/pacotes';

import {
  listarClientes,
  getCliente,
  saveCliente,
  paginarClientes,
} from './clientes';
import { Types as ClienteTypes } from '../ducks/clientes';

import { listarServicos } from './servicos';
import { Types as ServicoTypes } from '../ducks/servicos';

import { listarHorariosCliente, saveHorario } from './horarios';
import { Types as HorarioTypes } from '../ducks/horarios';

import { listarConvenios } from './convenios';
import { Types as ConvenioTypes } from '../ducks/convenios';

import {
  listarAnamneses,
  getGrupoAnamneseByPaciente,
  atualizarGrupoAnamnese,
} from './anamneses';
import { Types as AnamneseTypes } from '../ducks/anamneses';

import { listarAnotacoes } from './anotacoes';
import { Types as AnotacaoTypes } from '../ducks/anotacoes';

import { getCamposFormularioPorServico } from './campos';
import { Types as CampoTypes } from '../ducks/campos';

import {
  listarReceitasFinanceiras,
  listarContasReceberPorSessao,
  saveReceitaDespesa,
  saveReceitaDespesaMensal,
  getReceitaDespesa,
  saveFinanceiro,
  totalizarReceitas,
} from './financas';
import { Types as FinancaTypes } from '../ducks/financas';

import { listarGrupos } from './grupos';
import { Types as GrupoTypes } from '../ducks/grupos';

import {
  verificarNumeroPin,
  liberarNumeroPin,
  getEmpresaConfig,
} from './empresa';
import { Types as EmpresaTypes } from '../ducks/empresa';

import { registrarNovoCliente } from './register';
import { Types as RegisterTypes } from '../ducks/register';

export default function* rootSaga() {
  yield all([
    takeLatest(LoginTypes.DO_LOGIN_REQUEST, efetuarLogin),
    takeLatest(SalaTypes.LIST_REQUEST, listarSalas),
    takeLatest(PacoteTypes.LIST_REQUEST, listarPacotes),
    takeLatest(SessoesTypes.GET_REQUEST, getSessao),
    takeLatest(SessoesTypes.LIST_PAST_REQUEST, listarSessoesPassadas),
    takeLatest(SessoesTypes.LIST_FUTURE_REQUEST, listarSessoesFuturas),
    takeLatest(SessoesTypes.GET_SESSION_INCOME_REQUEST, getSessaoReceita),
    takeLatest(ClienteTypes.LIST_REQUEST, listarClientes),
    takeLatest(ClienteTypes.PAGE_REQUEST, paginarClientes),
    takeLatest(HorarioTypes.LIST_REQUEST, listarHorariosCliente),
    takeLatest(HorarioTypes.SAVE_REQUEST, saveHorario),
    takeLatest(ClienteTypes.GET_REQUEST, getCliente),
    takeLatest(ClienteTypes.SAVE_REQUEST, saveCliente),
    takeLatest(ServicoTypes.LIST_REQUEST, listarServicos),
    takeLatest(ConvenioTypes.LIST_REQUEST, listarConvenios),
    takeLatest(AnamneseTypes.LIST_REQUEST, listarAnamneses),
    takeLatest(AnotacaoTypes.LIST_REQUEST, listarAnotacoes),
    takeLatest(CampoTypes.GET_REQUEST, getCamposFormularioPorServico),
    takeLatest(SessoesTypes.SCORE_PRESENCE_REQUEST, scorePresencaSessao),
    takeLatest(SessoesTypes.SAVE_DETAIL_ABSENT_REQUEST, salvarDetalheAusencia),
    takeLatest(FinancaTypes.SAVE_REQUEST, saveReceitaDespesa),
    takeLatest(FinancaTypes.SAVE_MONTHLY_REQUEST, saveReceitaDespesaMensal),
    takeLatest(SessoesTypes.SAVE_REQUEST, salvarSessao),
    takeLatest(AgendaTypes.LIST_SCHEDULE_REQUEST, listarAgenda),
    takeLatest(AgendaTypes.ATTACH_SCHEDULE_REQUEST, anexarAgenda),
    takeLatest(FinancaTypes.SAVE_FINANCIAL_REQUEST, saveFinanceiro),
    takeLatest(AgendaTypes.SAVE_EVENTS_SCHEDULE_REQUEST, salvarEventoAgenda),
    takeLatest(AgendaTypes.REMOVE_EVENT_SCHEDULE_REQUEST, removerEventoAgenda),
    takeLatest(FinancaTypes.GET_INCOME_OR_EXPENSE_REQUEST, getReceitaDespesa),
    takeLatest(AnamneseTypes.SAVE_ANAMNESIS_REQUEST, atualizarGrupoAnamnese),
    takeLatest(GrupoTypes.LIST_REQUEST, listarGrupos),
    takeLatest(RegisterTypes.DO_REGISTER_REQUEST, registrarNovoCliente),
    takeLatest(EmpresaTypes.GET_CONFIG_EMPRESA_REQUEST, getEmpresaConfig),
    takeLatest(
      SessoesTypes.GET_SESSION_SCHEDULE_EVENT_REQUEST,
      getSessaoAgendaEvento
    ),
    takeLatest(
      FinancaTypes.LIST_FINANCIAL_INCOME_REQUEST,
      listarReceitasFinanceiras
    ),
    takeLatest(
      FinancaTypes.LIST_ACCOUNTS_RECEIVE_REQUEST,
      listarContasReceberPorSessao
    ),
    takeLatest(
      SessoesTypes.CONFIRM_ATTENDANCE_PATIENT_REQUEST,
      confirmarPresencaSessao
    ),
    takeLatest(
      FinancaTypes.GET_TOTAL_ACCOUNTS_RECEIVE_REQUEST,
      totalizarReceitas
    ),
    takeLatest(
      AgendaTypes.LIST_SCHEDULE_AND_ROOMS_REQUEST,
      listarSessoesSalasPorData
    ),
    takeLatest(
      EmpresaTypes.CHECK_NUMERO_PIN_EMPRESA_REQUEST,
      verificarNumeroPin
    ),
    takeLatest(
      EmpresaTypes.RELEASE_NUMERO_PIN_EMPRESA_REQUEST,
      liberarNumeroPin
    ),
    takeLatest(
      AnamneseTypes.GET_ANAMNESIS_GROUP_REQUEST,
      getGrupoAnamneseByPaciente
    ),
  ]);
}
