import { call, put } from 'redux-saga/effects';

import { Creators as ServicoActions } from '../ducks/servicos';

import API from '../../services/api';

export function* listarServicos() {
  try {
    const { data } = yield call(API.get, '/sessoes/servico-empresa');
    if (!data.records.length) {
      yield put(
        ServicoActions.listServicosFailure(
          'Nenhuma servico cadastrado até o momento!'
        )
      );
    } else {
      const { records } = data;
      yield put(ServicoActions.listServicosSuccess(records));
    }
  } catch (err) {
    yield put(
      ServicoActions.listServicosFailure(
        'Erro interno no módulo de listagem de serviços!'
      )
    );
  }
}
