import 'core-js/es6/array';

import 'core-js/es6/map';

import 'core-js/es6/set';
import 'core-js/es7/object';

(() => {
  if (typeof window.CustomEvent === 'function') return false;
  function CustomEvent(event, params) {
    // eslint-disable-next-line no-param-reassign
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    const evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(
      event,
      params.bubbles,
      params.cancelable,
      params.detail
    );
    return evt;
  }
  CustomEvent.prototype = window.Event.prototype;
  window.CustomEvent = CustomEvent;
  return true;
})();
