/* eslint-disable no-sequences */
/* eslint-disable no-unused-vars */

import { call, put, select } from 'redux-saga/effects';

import { Creators as AgendaActions } from '../ducks/agendas';
import { Creators as SessoesActions } from '../ducks/sessoes';

import API from '../../services/api';

export function* listarSessoesPassadas(action) {
  try {
    const { payload } = action;
    const { data } = yield call(
      API.get,
      `/sessoes/agrup-data/?q=(offset:${
        payload.offsetPast ? payload.offsetPast : 0
      })`
    );

    if (data.records.grupos) {
      yield put(
        SessoesActions.listSessoesPassadasSuccess([data.records.grupos])
      );
    } else {
      yield put(
        SessoesActions.listSessoesPassadasFailure(
          'Nenhuma sessão cadastrada até o momento!'
        )
      );
    }
  } catch (err) {
    yield put(
      SessoesActions.listSessoesPassadasFailure(
        'Erro interno no módulo de listagem das sessões passadas!'
      )
    );
  }
}

export function* listarSessoesFuturas(action) {
  try {
    const { payload } = action;
    const { data } = yield call(
      API.get,
      `/sessoes/agrup-data/?q=(futuro:1,offset:${
        payload.offsetFuture ? payload.offsetFuture : 0
      })`
    );

    if (data.records.grupos) {
      const grupos = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const key in data.records.grupos) {
        // eslint-disable-next-line no-prototype-builtins
        if (data.records.grupos.hasOwnProperty(key)) {
          grupos.unshift(data.records.grupos[key]);
        }
      }

      yield put(SessoesActions.listSessoesFuturasSuccess([grupos]));
    } else {
      yield put(
        SessoesActions.listSessoesFuturasFailure(
          'Nenhuma sessão cadastrada até o momento!'
        )
      );
    }
  } catch (err) {
    yield put(
      SessoesActions.listSessoesFuturasFailure(
        'Erro interno no módulo de listagem das sessões futuras!'
      )
    );
  }
}

export function* getSessao(action) {
  try {
    const { payload } = action;
    const { data } = yield call(
      API.get,
      `/sessoes/${payload.timeunix}?q=(offset:0)`
    );

    if (data.records) {
      const { records } = data;
      yield put(SessoesActions.getSessaoSuccess(records));
    } else {
      yield put(SessoesActions.getSessaoFailure('Sessão inexistente!'));
    }
  } catch (err) {
    yield put(
      SessoesActions.getSessaoFailure(
        'Erro interno no módulo de buscar os dados de uma determinada sessão!'
      )
    );
  }
}

export function* salvarSessao(action) {
  try {
    const { payload } = action;

    let response = null;
    if (payload.objSessao.time_unix) {
      response = yield call(API.put, '/sessoes/1', payload.objSessao);
    } else {
      response = yield call(API.post, '/sessoes', payload.objSessao);
    }

    const { data } = response;
    if (data.records.error) {
      yield put(SessoesActions.saveSessaoFailure(data.records.msg));
    } else {
      yield put(
        SessoesActions.saveSessaoSuccess('Sessão salva com sucesso! =D')
      );
    }
  } catch (err) {
    yield put(
      SessoesActions.saveSessaoFailure(
        'Erro interno no módulo para salvar os dados da sessão!'
      )
    );
  }
}

export function* scorePresencaSessao(action) {
  try {
    const { payload } = action;
    const { data } = yield call(
      API.post,
      '/sessao/atualizar-baixar-presenca',
      payload.objScoreSessao
    );

    if (data.records.error) {
      yield put(
        SessoesActions.scorePresencaSessaoFailure(
          'Ocorreu um erro ao tentar pontuar a presença do paciente na sessão!'
        )
      );
    } else {
      yield put(
        SessoesActions.scorePresencaSessaoSuccess(
          'Dados salvos com sucesso! =)'
        )
      );
    }
  } catch (err) {
    yield put(
      SessoesActions.scorePresencaSessaoFailure(
        'Erro interno no módulo para pontuar a presença do paciente na sessão!'
      )
    );
  }
}

export function* confirmarPresencaSessao(action) {
  try {
    const { payload } = action;
    const { data } = yield call(
      API.post,
      'financeiro/salvar-confirmar-sessao',
      payload.objConfirmaPresenca
    );
    if (data.records.error) {
      yield put(SessoesActions.confirmPresencaSessaoFailure(data.records.msg));
    } else {
      yield put(SessoesActions.confirmPresencaSessaoSuccess(data.records.msg));
    }
  } catch (err) {
    yield put(
      SessoesActions.confirmPresencaSessaoSuccess(
        'Erro interno no módulo para confirmação de presença do paciente na sessão!'
      )
    );
  }
}

export function* getSessaoReceita(action) {
  try {
    const { payload } = action;
    const { data } = yield call(
      API.get,
      `/sessao/get-evento-receita/${payload.timeunix}`
    );

    if (data.records) {
      const { records } = data;
      yield put(SessoesActions.getSessaoReceitaSuccess(records));
    } else {
      yield put(SessoesActions.getSessaoReceitaFailure('Sessão inexistente!'));
    }
  } catch (err) {
    yield put(
      SessoesActions.getSessaoReceitaFailure(
        'Erro interno no módulo de buscar os dados de uma determinada sessão!'
      )
    );
  }
}

export function* getSessaoAgendaEvento(action) {
  try {
    const { payload } = action;
    const { data } = yield call(
      API.get,
      `/sessao/evento/0|${payload.timeunix}`
    );

    if (data.records) {
      const { records } = data;
      yield put(SessoesActions.getSessaoAgendaEventoSuccess(records));
    } else {
      yield put(
        SessoesActions.getSessaoAgendaEventoFailure('Sessão inexistente!')
      );
    }
  } catch (err) {
    yield put(
      SessoesActions.getSessaoAgendaEventoFailure(
        'Erro interno no módulo de buscar os dados de um determinado evento!'
      )
    );
  }
}

export function* salvarDetalheAusencia(action) {
  try {
    const { payload } = action;
    const { data } = yield call(
      API.post,
      '/sessao/salvar-detalhe-ausencia',
      payload.objDetalheAusencia
    );

    if (data.records.error) {
      const { records } = data;
      yield put(SessoesActions.saveDetalheAusenciaFailure(records.msg));
    } else {
      yield put(
        SessoesActions.saveDetalheAusenciaSuccess(
          'Dados salvos com sucesso! =)'
        )
      );
    }
  } catch (err) {
    yield put(
      SessoesActions.saveDetalheAusenciaFailure(
        'Erro interno no módulo para salvar o detalhe de ausência numa sessão!'
      )
    );
  }
}

export function* listarAgenda(action) {
  try {
    const { payload } = action;
    const { data } = yield call(API.get, `/sessao/evento/${payload.query}`);

    if (!data.records.length) {
      yield put(
        AgendaActions.listAgendasFailure(
          'Nenhum evento cadastrado até o momento!'
        )
      );
    } else {
      const { records } = data;
      yield put(AgendaActions.listAgendasSuccess(records));
    }
  } catch (err) {
    yield put(
      AgendaActions.listAgendasFailure(
        'Erro interno no módulo de listagem da agenda!'
      )
    );
  }
}

export const getAgendas = state => state.agendas;

export function* anexarAgenda(action) {
  try {
    const { payload } = action;
    const { data } = yield call(API.get, `/sessao/evento/${payload.query}`);

    if (!data.records.length) {
      yield put(
        AgendaActions.attachAgendasFailure(
          'Nenhum evento cadastrado até o momento!'
        )
      );
    } else {
      const agendas = yield select(getAgendas);
      const cloneSessoes = [...agendas.data];

      const { records } = data;
      let sessoes = [];
      if (cloneSessoes.length) {
        sessoes = [
          ...[records, cloneSessoes]
            .reduce(
              (m, a) => (
                a.forEach(
                  o =>
                    (m.has(o.id) && Object.assign(m.get(o.id), o)) ||
                    m.set(o.id, o)
                ),
                m
              ),
              new Map()
            )
            .values(),
        ];
      } else {
        sessoes = records;
      }

      yield put(AgendaActions.attachAgendasSuccess(sessoes));
    }
  } catch (err) {
    yield put(
      AgendaActions.attachAgendasFailure(
        'Erro interno no módulo de listagem da agenda!'
      )
    );
  }
}

export function* salvarEventoAgenda(action) {
  try {
    const { payload } = action;

    let response = null;
    if (payload.object.time_unix) {
      response = yield call(
        API.put,
        `/sessao/evento/${payload.object.cod_evento}`,
        payload.object
      );
    } else {
      response = yield call(API.post, '/sessao/', payload.object);
    }

    const { data } = response;
    if (data.records.error) {
      yield put(AgendaActions.saveEventoAgendaFailure(data.records.msg));
    } else {
      yield put(AgendaActions.saveEventoAgendaSuccess(data.records.time_unix));
    }
  } catch (err) {
    yield put(
      AgendaActions.saveEventoAgendaFailure(
        'Erro interno no módulo de salvar evento na agenda!'
      )
    );
  }
}

export function* removerEventoAgenda(action) {
  try {
    const { payload } = action;
    const { data } = yield call(API.delete, `/sessao/evento/${payload.id}`);

    if (data.records.error) {
      const { records } = data;
      yield put(AgendaActions.removeEventoAgendaFailure(records.msg));
    } else {
      yield put(AgendaActions.removeEventoAgendaSuccess(payload.id));
    }
  } catch (err) {
    yield put(
      AgendaActions.removeEventoAgendaFailure(
        'Erro interno no módulo de remover um evento da agenda!'
      )
    );
  }
}

export function* listarSessoesSalasPorData(action) {
  try {
    const { payload } = action;
    const { data } = yield call(
      API.get,
      `/sessoes/listar-sessoes-salas-dia/${payload.query}`
    );

    if (!data.records.sessoes.length) {
      yield put(
        AgendaActions.listAgendaSalasFailure(
          'Nenhum evento cadastrado até o momento!'
        )
      );
    } else {
      const { records } = data;
      yield put(AgendaActions.listAgendaSalasSuccess(records));
    }
  } catch (err) {
    yield put(
      AgendaActions.listAgendaSalasFailure(
        'Erro interno no módulo de listagem das sessões!'
      )
    );
  }
}
