const Constants = {
  LANGUAGE: {
    BR: '1',
    PT: '2',
    EN: '3',
    FR: '4',
    ES: '5',
  },
  COUNTRY: {
    BR: '1',
    PT: '2',
    AR: '3',
    CL: '4',
    CO: '5',
    MX: '6',
    PE: '7',
    UR: '8',
    EC: '9',
  },
  /* Código do país retrata o índice do no vetor de DDI: */
  DDI: [
    '+55',
    '+55',
    '+351',
    '+54',
    '+56',
    '+57',
    '+52',
    '+51',
    '+598',
    '+593',
  ],
  ISO_COUNTRY: ['BR', 'BR', 'PT', 'AR', 'CL', 'CO', 'MX', 'PE', 'UY', 'EC'],
  FIELDS: {
    TYPE_INPUT: 1,
    TYPE_TEXTAREA: 2,
    TYPE_CHECKBOX: 4,
    TYPE_SIMPLE_SELECT: 3,
    TYPE_COMPLEX_SELECT: 5,
  },
  CHARGE: {
    TYPE_SESSION: 1,
    TYPE_PACKAGE: 2,
    TYPE_MONTHLY: 3,
    TYPE_HEALTH_INSURANCE: 4,
    TYPE_FREE: 5,
  },
};

export default Constants;
